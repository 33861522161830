/* eslint-disable max-len */
/* eslint-disable no-dupe-keys */

import { Hub, Logger } from 'aws-amplify';
import { SSMLObject } from '../components/AssetsScene';

const logger = new Logger('SceneElements.js');

const worldData = {
    myElements: {},
    myElementGroups: {},
    myCurrentGroups: [],
    homeTimeout: 500,
    captionModifier: {
      warn: {
        token: '_warn_',
        func: arg => `<span style="color:orange"><strong>${arg.split('_warn_')[1]}</strong></span>`,
      },
      green: {
        token: '_g_',
        func: arg => `<span style="color:green">${arg.split('_g_')[1]}</span>`,
      },
      bold: {
        token: '__',
        func: arg => `<span style="color:#22cdf4"><strong>${arg.split('__')[1]}</strong></span>`,
      },
	},
	lexToolingMod: {
		elementName: {
		  token: {
			begin: '__',
			end: '__',
		  }, // end required for surround type
		  speech: (fullMatch, captured) => {
			let replacement = 'unknown element';
			return `<mark name='showElement:${captured}'/>${
			  worldData.myElements[captured].text || replacement
			}<break time='110ms'/>`;
		  },
		  caption: (fullMatch, captured) => {
			// if no end, assumed single-token replacement
			return `<span style="color: #ffe146;padding: 0.085em;font-size: 0.92em;font-weight:bold;border-radius: 1vh;"><strong>${captured}</strong></span>`;
		  },
		  before: () => {
			// return logger.debug("in before callback - arg: ", ctx);
		  },
		  after: () => {
			// return logger.debug("in after callback -arg: ", ctx);
		  },
		},
		waiting: {
		  // capture | standalone (no end)
		  token: {
			begin: '(:waitRandom)',
		  }, // end required for surround type
	
		  before: () => {
			return logger.debug('in before callback');
		  },
		  after: ctx => {
			SSMLObject.listenerSsml([
				'emote:waitingRandom',
				'animate:waitingRandom',
				'cam:idleAnimation'
			]);
			return logger.debug('in after callback -arg: ', ctx);
		  },
		  speech: (fullMatch, captured) => {
			return ''; // remove token from message in ssml destined for Polly
		  },
		},
		goSomewhere: {
		  // capture | standalone (no end)
		  token: {
			begin: '(:go.+?)',
		  }, // end required for surround type
	
		  after: (ctx, fullMatch, captured) => {
			switch (captured) {
			  case '(:goHome)':
				SSMLObject.listenerSsml([
					'navigateTo:/',
					'emit:cancelTutorial',
					'transitionBackground:livingroom',
					'emit:camAddison',
					'lexPlayScene:Home',
				  ]);
				break;
			  default:
				break;
			}
		  },
		  speech: (fullMatch, captured) => {
			return ''; // remove token from message in ssml destined for Polly
		  },
		},
	  },
  };

export const ctx = {
	worldData, 
};
window._ctx = ctx;
setup(ctx);

function setup(ctx) {
  const logger = new Logger('SceneElements.js/setup');
	/* 
	Updated and Written by Martin Jencka - 7/12/2019.

	
	!!! This script handles setting up element creation, and simplifies the process greatly. Simply add your elements and elementgroups to the lists !!!

	You can call an element from lex or a speech file by using the following commands: 
	 
	Displaying an Element: 
	<mark name="showElement:elementName"/>
	
	Hiding an Element:
	<mark name="hideElement:elementName"/>
	
	Showing an Element Group:
	<mark name="showGroup:groupName"/>
	
	Hiding an Element Group:
	<mark name="hideGroup:groupName"/>

	Hiding all non stickied groups:
	<mark name="hideGroups"/>

	Elements are UI things that need animation. Outlines, tables, etc, go here.
	This is where we setup shorthand for speech to call, or for another function to call, without us having to put a whole element summon in a speech file.
	Use the default options as examples to expand upon.
	Use sEmit for a sumerian emit, and emit for an amplify emit. You can have both.
	
	
	 * Button Types:
	Here is a list of all button types we can adjust. This should account for any button in the scene
	All buttons should be modular and allow for any value, with the exception of *maybe* start and stop listening. That may change.

	Each of these is structured like this
	 - id : Description. When you're creating a button, the id of the type is the only thing you should worry about.

	 - $ at beginning of emit means it will send to the sumerian system bus instead of the amplify hub.

	= Top Bar Buttons = (These probably won't need to be changed from defaults, but who knows)
	 - assist : Assist
	 - addison : Addison
	 - listen : Stop Listening / Start Listening (Probably not modular, hardcoded)
	 - tertiary : Tertiary / button on top. Used primarily for testing.

	= Three Button Sidebar =
	 - sBtn1 : Sidebar Button 1
	 - sBtn2 : Sidebar Button 2
	 - sBtn3 : Sidebar Button 3

	= Yes / No Sidebar =
	 - sYes : Sidebar Yes
	 - sNo : Sidebar No

	= Sidebar Other =
	 - sDone : Sidebar Done
	 - sReady : Sidebar Ready (The one with the flag?)


	* Element Types
	Elements are functionally the same as buttons, but do not use any onclick events. This may be graphs, tables, graphics, titles, etc.

	= Tables =
	 - mTable : Medical Table. Used for most tables / Lists
	 - scatterChart : Scatter Chart, default addison scatter chart.
	
	 **************************************************************************************
	 *																					  *
	 * 								  	  Elements										  *
	 * 																					  *
	 ************************************************************************************* */

	ctx.elements = {
		// Top Bar
		assist: { text: "Assist", type: "assist", emit: "assist" },
		addison: { text: "Addison", type: "addison", sEmit: "activeListen", delay: "50" },
		listen: { text: "", type: "listen", emit: "listen" },
		reload: { text: "Reload", type: "tertiary", emit: "reload", delay: "100" },

		sampleButton1: { text: "Button 1", type: "sBtn1", sEmit: "post_to_lex:button 1", id: "btn1" }, // Blood pressure select
		sampleButton2: { text: "Button 2", type: "sBtn2", sEmit: "post_to_lex:button 2", id: "btn2" }, // Blood pressure select

		sampleVideo: { type: "fullscreenVideo", link: "https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/BPTutorial_v01.mp4" },

		bloodPressure: { text: "Blood Pressure", to: "/sceneVitals/takeVital/bloodPressure", type: "sidebarButton1", sEmit: "post_to_lex:bloodpressure", icon: "BloodPressure" },
		pulseOximeter: { text: "Pulse Oximeter", to: "/sceneVitals/takeVital/bloodOxygen", type: "sidebarButton2", sEmit: "post_to_lex:pulseox", icon: "PulseOximeter"}, 
		glucose: { text: "Glucometer", to: "/sceneVitals/takeVital/glucose", type: "sidebarButton4", sEmit: "post_to_lex:glucose", icon: "Glucometer" },
		temperature: { text: "Temperature", to: "/sceneVitals/takeVital/temperature", type: "sidebarButton3", sEmit: "post_to_lex:thermometer", icon: "Temperature" },
		weight: { text: "Weight", to: "/sceneVitals/takeVital/weight", type: "sidebarButton5", sEmit: "post_to_lex:weight", icon: "Weight" },

		bloodPressureHistory: { text: "Blood Pressure", to: "/sceneVitals/history/bloodPressure", type: "sidebarButton1", sEmit: "post_to_lex:bloodpressure", icon: "BloodPressureHistory" },
		pulseOximeterHistory: { text: "Pulse Oximeter", to: "/sceneVitals/history/bloodOxygen", type: "sidebarButton2", sEmit: "post_to_lex:pulseox", icon: "PulseOximeterHistory" },
		glucoseHistory: { text: "Glucometer", to: "/sceneVitals/history/glucose", type: "sidebarButton4", sEmit: "post_to_lex:glucose", icon: "GlucometerHistory" },
		temperatureHistory: { text: "Temperature", to: "/sceneVitals/history/temperature", type: "sidebarButton3", sEmit: "post_to_lex:thermometer", icon: "TemperatureHistory" },
		weightHistory: { text: "Weight", to: "/sceneVitals/history/weight", type: "sidebarButton5", sEmit: "post_to_lex:weight", icon: "WeightHistory" },

		takeVital: { text: "Take Vital", to: "/sceneVitals/takeVital", type: "sidebarButton1", sEmit: "post_to_lex:take vital" },
		takeHistory: { text: "Review History", to: "/sceneVitals", type: "sidebarButton2", sEmit: "post_to_lex:show history" },
		showTutorial: { text: "Tutorial", to: "/sceneVitals/showTutorial", type: "sidebarButton1", sEmit: "post_to_lex:tutorial" },
		vitalReady: { text: "Ready", to: "/sceneVitals/vitalReady", type: "sidebarButton2", sEmit: "post_to_lex:ready", icon: "Yes" },
		vitalDone: { text: "Done", to: "/sceneVitals/takeVital/result", type: "sidebarDone", sEmit: "post_to_lex:done", icon: "Done" },
		historyDone: { text: "Done", to: "/sceneVitals/historyDone", type: "sidebarDone", sEmit: "post_to_lex:done", icon: "Done" },
		menuDone: { text: "Done", to: "/sceneVitals", type: "sidebarDone", sEmit: "post_to_lex:show vital menu", icon: "Done" },
		// 		allDone						: {text:"Done",				to:"/",									type:"sidebarDone", sEmit:"loadSceneByName:Home"},
		allDone: { text: "Done", to: "/", type: "sidebarDone", icon: "Done" },

		yes: { text: "Yes",				/* to:"/",	*/								type: "sidebarYes", sEmit: "post_to_lex:yes", icon: "Yes" },
		no: { text: "No",				/* to:"/",   */								type: "sidebarNo", sEmit: "post_to_lex:no", icon: "No" },

		yesVitalResult: { text: "Yes", to: "/sceneVitals/takeVital/result", type: "sidebarYes", sEmit: "post_to_lex:yes", icon: "Yes" },
		noVitalResult: { text: "No", to: "/sceneVitals", type: "sidebarNo", sEmit: "post_to_lex:no", icon: "No" },

	}


	// TODO PULSE OX HAS TWO VARIABLES HEART RATE AND BLOOD OXYGEN
	//	make switch that 
	//	
	/* *************************************************************************************
	 *																					  *
	 * 								    Element Groups									  *
	 * 																					  *
	 **************************************************************************************

	 Element groups.
	 This allows us to create, say, one group for the top bar, and hide and show that as it's own thing. */

	ctx.groups = {
		topBar: { elements: ["assist", "addison", "listen"], stick: true },
		exampleSidebar: { elements: ["sampleButton1", "sampleButton2"] },
		vitalTypes: { elements: ["bloodPressure", "pulseOximeter", "glucose", "temperature", "weight", "menuDone"] },
		vitalTypesHistory: { elements: ["bloodPressureHistory", "pulseOximeterHistory", "glucoseHistory", "temperatureHistory", "weightHistory", "menuDone"] },
		takeHistory: { elements: ["takeVital", "takeHistory", "allDone"] },
		takeVitalMenu: { elements: ["showTutorial", "vitalReady", "vitalDone"] },
		doneVital: { elements: ["takeVital", "vitalDone"] },
		doneHistory: { elements: ["takeHistory", "menuDone"] },
		readyVital: { elements: ["vitalReady"] },
		readyHistory: { elements: ["historyReady"] },
		yesNo: { elements: ["yes", "no"] },
		yesNoVitalResult: { elements: ["yesVitalResult", "noVitalResult"] },

		// 		bpTakeHist					: {elements: 	["bpTake",	"bpHistory"] },
		// 		poTakeHist					: {elements: 	["pulseOxTake",	"pulseOxHistory"] },
		// 		tempTakeHist				: {elements: 	["tempTake",	"tempHistory"] },
		// 		weightTakeHist				: {elements: 	["weightTake",	"weightHistory"] },
		// 		glucoseTakeHist				: {elements: 	["glucoseTake",	"glucoseHistory"] },

	}


	// The currently active groups, used for removing groups.
	ctx.currentGroups = [];
	// The current 'Screen', aka the current group that is open.
	ctx.currentScreen = "topBar";


	/**************************************************************************************
	 *																					  *
	 * 							   Core Setup Functionality								  *
	 * 																					  *
	 ************************************************************************************* */

	/********************************************************************************
	  Enable Element Function - Pass an element name and it will enable that element
	******************************************************************************** */
	ctx.showElementFunc = function (capsule) {
		const { payload } = capsule;
		const elm = payload.data;

		//Here is where we add custom whatevers for element showing. Most should not use this.
		if (elm === "custom") {
			if (ctx.elements[elm] != null) // If the element exists in our element definition.
			{

			}
		} else {
			if (ctx.elements[elm] != null) // If the element exists in our element definition.
			{

				let payload = {};
				payload.elements = [];
				payload.elements.push(ctx.elements[elm]);
				Hub.dispatch("showAmplifyElement", { data: payload});
			}
			else // Otherwise, error out. Make sure that the element exists in ctx.elements above!
			{
				console.error("Trying to create element '" + elm + "' not listed in JS_SceneElements");
				
			}
		}

	};

	/********************************************************************************
	  Disable Element Function - Pass an element name and it will disable that element
	******************************************************************************** */
	ctx.hideElementFunc = function (capsule) {
		const { payload } = capsule;
		const elm = payload.data;
		logger.debug("hiding element");
		if (elm === "Custom") // If the element exists in our element definition.
		{

		} else {
			if (ctx.elements[elm] != null) // If the element exists in our element definition.
			{
				let payload = {};
				payload.elements = [];
				payload.elements.push(ctx.elements[elm]);
				Hub.dispatch("hideAmplifyElement", { data: payload });
			}
			else // Otherwise, error out. Make sure that the element exists in ctx.elements above!
			{
				console.error("Trying to create element '" + elm + "' not listed in JS_SceneElements");
				
			}
		}

	};


	/********************************************************************************
	  Reload Element Function - Pass an element name and it will reload that element
	******************************************************************************** */
	ctx.reloadElementFunc = function (capsule) {
		const { payload } = capsule;
		const elm = payload.data;

		if (ctx.elements[elm] != null) // If the element exists in our element definition.
		{

			let payload = {};
			payload.elements = [];
			payload.elements.push(ctx.elements[elm]);
			payload.reload = true;
			Hub.dispatch("showAmplifyElement", { data: payload });
		}
		else // Otherwise, error out. Make sure that the element exists in ctx.elements above!
		{
			console.error("Trying to reload element '" + elm + "' not listed in JS_SceneElements");
			
		}

	};

	/*************************************************************************************
	  Show Group Function - Pass a group name and it will show any elements in that group.
	************************************************************************************* */
	ctx.showGroupFunc = function (capsule) {
		const { payload } = capsule;
		const grp = payload.data;

		//Here is where we add custom groupings for vitals.
		if (ctx.groups[grp] != null) // If the element exists in our element definition.
		{
			logger.debug("Showing Group " + grp);
			//Iterate through every element in that group.
			for (let i = 0; i < ctx.groups[grp].elements.length; i++) {
				let elm = ctx.groups[grp].elements[i];

				if (ctx.elements[elm] != null) {
					let payload = {};
					payload.elements = [];
					payload.elements.push(ctx.elements[elm]);
					Hub.dispatch("showAmplifyElement", { data: payload });
				} else {
					console.error("Element '" + elm + "'listed in group '" + grp + "' does not exist.");
				}
			}

			ctx.currentGroups.push(grp);
			ctx.currentScreen = grp;
		}
		else // Otherwise, error out. Make sure that the element exists in ctx.elements above!
		{
			console.error("Trying to create group not listed in JS_SceneElements");
			
		}
	};

	/*************************************************************************************
	  Hide Group Function - Pass a group name and it will hide any elements in that group.
	************************************************************************************* */
	ctx.hideGroupFunc = function (capsule) {
		const { payload } = capsule;
		const grp = payload.data;

		//Here is where we add custom groupings for vitals.
		if (ctx.groups[grp] != null) // If the element exists in our element definition.
		{

			//Iterate through every element in that group.
			for (let i = 0; i < ctx.groups[grp].elements.length; i++) {
				let elm = ctx.groups[grp].elements[i];

				if (ctx.elements[elm] != null) {
					let payload = {};
					payload.elements = [];
					payload.elements.push(ctx.elements[elm]);
					Hub.dispatch("hideAmplifyElement", { data: payload });
				} else {
					console.error("Removing Element '" + elm + "'listed in group '" + grp + "' does not exist.");
				}
			}

			//Remove the group from currentgroups.
			for (var i = 0; i < ctx.currentGroups.length; i++) {
				if (ctx.currentGroups[i] === grp) {
					ctx.currentGroups.splice(i, 1);
				}
			}
		}
	};

	// Hide all non sticky groups. Used for removing whatever is currently open.
	ctx.hideAllNonStickyGroups = function () {
		for (let i = 0; i < ctx.currentGroups.length; i++) {
			if (ctx.groups[ctx.currentGroups[i]].stick == null) {
				ctx.hideGroupFunc(ctx.currentGroups[i]);
			}
		}
	}

	// Create the topbar at the beginning of the scene.
	// ctx.showGroupFunc({ data: "topBar" });

	// Listener setup
	/**
	Hub.listen('showElement', ctx.showElementFunc);
	Hub.listen('hideElement', ctx.hideElementFunc);

	Hub.listen('showGroup', ctx.showGroupFunc);
	Hub.listen('hideGroup', ctx.hideGroupFunc);

	Hub.listen('hideAllGroups', ctx.hideAllNonStickyGroups);
	**/

	Hub.dispatch('showGroup', { data: "topBar"} )
}



// Called when play mode stops.
//
function cleanup(ctx) {
	Hub.remove('showElement');
	Hub.remove('hideElement');
	Hub.remove('showGroup');

	Hub.remove('hideGroup');

	Hub.remove('hideAllGroups');

	Hub.remove('setVitalGraph');

}

export const ElementManagerTooling = {
	setup,
	cleanup,
	ctx
}