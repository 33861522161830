import * as BABYLON from 'babylonjs';
import { Hub, Logger } from 'aws-amplify';

export async function initClothingSwap(scene) {
    const logger = new Logger('initClothingSwap.js');

    // Define clothing material
    var clothesMat = scene.getMaterialByName("Clothes_MAT_v01");
    console.log("CLOTHES_MAT_V01: ", clothesMat)

    // 6 Color Shirt Swap Textures
    const redShirt = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Minimal_Red_Diff_v01.jpg", scene);
    const orangeShirt = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Minimal_Orange_Diff_v01.jpg", scene);
    const greenShirt = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Minimal_Green_Diff_v01.jpg", scene);
    const blueShirt = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Minimal_Blue_Diff_v01.jpg", scene);
    const purpleShirt = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Minimal_Purple_Diff_v01.jpg", scene);
    const pinkShirt = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Minimal_Pink_Diff_v01.jpg", scene);
    
    const sixColorNorm = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Minimal_Color_Norm_v01.jpg", scene)
    const sixColorORM = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_6Color_ORM_v01_01.jpg", scene)


    // 6 Color Shirt Swap Functions
    const _redShirt = () => {
            clothesMat.albedoTexture = redShirt;
            clothesMat.albedoTexture.vScale = -1
            clothesMat.bumpTexture = sixColorNorm
            clothesMat.bumpTexture.vScale = -1
    };
    const _orangeShirt = () => {
        clothesMat.albedoTexture = orangeShirt;
        clothesMat.albedoTexture.vScale = -1
        clothesMat.bumpTexture = sixColorNorm
        clothesMat.bumpTexture.vScale = -1
    };
    const _greenShirt = () => {
        clothesMat.albedoTexture = greenShirt;
        clothesMat.albedoTexture.vScale = -1
        clothesMat.bumpTexture = sixColorNorm
        clothesMat.bumpTexture.vScale = -1
        };
    const _blueShirt = () => {
        clothesMat.albedoTexture = blueShirt;
        clothesMat.albedoTexture.vScale = -1
        clothesMat.bumpTexture = sixColorNorm
        clothesMat.bumpTexture.vScale = -1
    };
    const _purpleShirt = () => {
        clothesMat.albedoTexture = purpleShirt;
        clothesMat.albedoTexture.vScale = -1
        clothesMat.bumpTexture = sixColorNorm
        clothesMat.bumpTexture.vScale = -1
    };
    const _pinkShirt = () => {
        clothesMat.albedoTexture = pinkShirt;
        clothesMat.albedoTexture.vScale = -1
        clothesMat.bumpTexture = sixColorNorm
        clothesMat.bumpTexture.vScale = -1
    };
    


    const _startShirtSwap = async (capsule) => {
        const { payload } = capsule;

        logger.debug(`switching shirt color to ${payload.data}`)

        switch (payload.data) {
            case 'red':
                _redShirt();
                break;
            case 'orange':
                _orangeShirt();
                break;
            case 'green':
                _greenShirt();
                break;
            case 'blue':
                _blueShirt();
                break;
            case 'purple':
                _purpleShirt();
                break;
            case 'pink':
                _pinkShirt();
                break;

                
            default:
                logger.debug('invalid weather type for particle support');
        }
    }


    Hub.listen('shirtColor', _startShirtSwap);
}