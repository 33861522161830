import { Hub, Logger } from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';
import { postToLex } from '../js';

const logger = new Logger('HomeScene');

async function setup() {
	console.log("....... in home scene setup");
	/* 
	!!! This script handles setting up element creation, and simplifies the process greatly. Simply add your elements and elementgroups to the lists !!!

	You can call an element from lex or a speech file by using the following commands: 
	 
	Displaying an Element: 
	<mark name="showElement:elementName"/>
	
	Hiding an Element:
	<mark name="hideElement:elementName"/>
	
	Showing an Element Group:
	<mark name="showGroup:groupName"/>
	
	Hiding an Element Group:
	<mark name="hideGroup:groupName"/>

	Hiding all non stickied groups:
	<mark name="hideGroups"/>

	Elements are UI things that need animation. Outlines, tables, etc, go here.
	This is where we setup shorthand for speech to call, or for another function to call, without us having to put a whole element summon in a speech file.
	Use the default options as examples to expand upon.
	Use sEmit for a sumerian emit, and emit for an amplify emit. You can have both.
	
	
	 * Button Types:
	Here is a list of all button types we can adjust. This should account for any button in the scene
	All buttons should be modular and allow for any value, with the exception of *maybe* start and stop listening. That may change.

	Each of these is structured like this
	 - id : Description. When you're creating a button, the id of the type is the only thing you should worry about.

	 - $ at beginning of emit means it will send to the sumerian system bus instead of the amplify hub.

	= Top Bar Buttons = (These probably won't need to be changed from defaults, but who knows)
	 - assist : Assist
	 - addison : Addison
	 - listen : Stop Listening / Start Listening (Probably not modular, hardcoded)
	 - tertiary : Tertiary / button on top. Used primarily for testing.

	= Three Button Sidebar =
	 - sBtn1 : Sidebar Button 1
	 - sBtn2 : Sidebar Button 2
	 - sBtn3 : Sidebar Button 3

	= Yes / No Sidebar =
	 - sYes : Sidebar Yes
	 - sNo : Sidebar No

	= Sidebar Other =
	 - sDone : Sidebar Done
	 - sReady : Sidebar Ready (The one with the flag?)


	* Element Types
	Elements are functionally the same as buttons, but do not use any onclick events. This may be graphs, tables, graphics, titles, etc.

	= Tables =
	 - mTable : Medical Table. Used for most tables / Lists
	 - scatterChart : Scatter Chart, default addison scatter chart.
	
	 **************************************************************************************
	 *																					  *
	 * 								  	  Elements										  *
	 * 																					  *
	 **************************************************************************************/

	store.getActions().addMyElements({

	//************** VITALS SCENE *******************

		takeVital: {			text: "Take Vital",			to: "/sceneVitals/takeVital",		type: "sidebarButton1",			sEmit: "post_to_lex:take vital",			icon: "Vitals",				id: "btn1",		},
		TakeVital: {			text: "Take Vital",			to: "/sceneVitals/takeVital",		type: "sidebarButton1",			sEmit: "post_to_lex:take vital",			icon: "Vitals",				id: "btn1",		},

		takeHistory: {			text: "Review History",		to: "/sceneVitals",					type: "sidebarButton2",			sEmit: "post_to_lex:show history",			icon: "VitalsHistory",		id: "btn2",		},
		TakeHistory: {			text: "Review History",		to: "/sceneVitals",					type: "sidebarButton2",			sEmit: "post_to_lex:show history",			icon: "VitalsHistory",		id: "btn2",		},

		vitalDone: {			text: "Done",				to: "/sceneVitals/takeVital",		type: "sidebarDone",			sEmit: "post_to_lex:show menu",				icon: "Done",		},
		VitalDone: {			text: "Done",				to: "/sceneVitals/takeVital",		type: "sidebarDone",			sEmit: "post_to_lex:show menu",				icon: "Done",		},

		allBack: {				text: "Back",				to: "/",							type: "sidebarDone",			sEmit: "post_to_lex:done",					icon: "Left",		},

		tutorial: { 			text: "Tutorial", 												type: "tutorial", emit: "tutorial", sEmit: "post_to_lex:tutorial", 			icon: "Tutorial" },
		Tutorial: { 			text: "Tutorial", 												type: "tutorial", emit: "tutorial", sEmit: "post_to_lex:tutorial",			icon: "Tutorial" },

		reload: { 				text: "Reload", 												type: "tertiary", emit: "reload", 											icon: "" },
		Reload: { 				text: "Reload",													type: "tertiary", emit: "reload", 											icon: "" },


	//************** HOME SCENE/universal? *******************

		MedicationsButton: {	text: "Medications", 	setLexConfig: 'medications', 			type: "sidebarButton1", 	emit: "defaultAnim", 	sEmit:"post_to_lex:medications", 		icon:"Medication",		id:"Sbtn1", }, 	 //Medications select
		VitalsButton: {			text: "Vitals", 		setLexConfig: 'vitals', 				type: "sidebarButton2", 	emit: "defaultAnim", 	sEmit:"post_to_lex:vitals", 			icon:"Vitals", 			id:"btn2", },  //Vitals select
		DoneButton: {			text: "Done", 													type: "sidebarDone", 		emit: "defaultAnim",	sEmit:"post_to_lex:done", 				icon:"Done", 			id:"sDone", }, //Manage Medications select
		BackButton: {			text: "Back",													type: "sidebarDone", 		emit: "defaultAnim",	sEmit:"post_to_lex:done", 				icon:"Left"}, // Back button

		MotionButton: {			text: "Motion", 												type:"sidebarButton3", 	emit:"defaultAnim",		sEmit:"post_to_lex:motionanalysis", 	icon:"Motion"},			id:"btn3", 	 //Motion select
		MotionAnalysisButton: {	text: "Test Gait", 												type:"sidebarButton1", 	emit:"defaultAnim",		sEmit:"post_to_lex:motionanalysis", 	icon:"GaitAnalysis"},   id:"btn1", //Motion Analysis select
		
		emergencyButton: { 		text: "Emergency",												type: "sidebarButton1Red", emit: ["defaultAnim", "playHelpScene", "hideAllElements"], sEmit: "post_to_lex:emergency", icon: "Emergency" },
		EmergencyButton: { 		text: "Emergency", 												type: "sidebarButton1Red", emit: ["defaultAnim", "playHelpScene", "hideAllElements"], sEmit: "post_to_lex:emergency", icon: "Emergency" },
		tutorialButton: { 		text: "Tutorial", 												type: "sidebarButton3", emit: "defaultAnim", sEmit: "post_to_lex:tutorial", 									  icon: "Tutorial" },
		TutorialButton: { 		text: "Tutorial", 												type: "sidebarButton3", emit: "defaultAnim", sEmit: "post_to_lex:tutorial", 									  icon: "Tutorial" },

		PocketMDButton: { 		text: "pocketMD", 												type: "sidebarButton2", emit: ["defaultAnim", "playHelpScene", "hideAllElements"], sEmit: "post_to_lex:pocketMD", icon: "PocketMD" },
		pocketMDButton: { 		text: "pocketMD", 												type: "sidebarButton2", emit: ["defaultAnim", "playHelpScene", "hideAllElements"], sEmit: "post_to_lex:pocketMD", icon: "PocketMD" },

		supportButton: { 		text: "Customer Support", 										type: "sidebarButton3", emit: "defaultAnim", sEmit: "post_to_lex:support", 										  icon: "CustomerSupport" },
		SupportButton: { 		text: "Customer Support", 										type: "sidebarButton3", emit: "defaultAnim", sEmit: "post_to_lex:support", 										  icon: "CustomerSupport" },

		AlexaButton: { 			type: "alexaButton", 																	emit: "defaultAnim", sEmit: "post_to_lex:Alexa", 										  icon: "Alexa" },
		alexaButton: { 			type: "alexaButton", 																	emit: "defaultAnim", sEmit: "post_to_lex:Alexa", 										  icon: "Alexa" },
		lowCancel: { 			text: "Back", 													type: "sidebarDone", 	emit: "defaultAnim", sEmit: "post_to_lex:home", icon: "Left" },
		LowCancel: { 			text: "Back", 													type: "sidebarDone", 	emit: "defaultAnim", sEmit: "post_to_lex:home", icon: "Left" },

		backCancel: { 			text: "Cancel",													type: "sidebarDone", 	emit: "defaultAnim", sEmit: "post_to_lex:cancel", icon: "No" },
		BackCancel: { 			text: "Cancel", 												type: "sidebarDone", 	emit: "defaultAnim", sEmit: "post_to_lex:cancel", icon: "No" },

		sampleVideo: { 																			type: "fullscreenVideo", link: "https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/BPTutorial_v01.mp4" },
		SampleVideo: { 																			type: "fullscreenVideo", link: "https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/BPTutorial_v01.mp4" },

		Home: { 	text: "Home", setLexConfig: "home",      type: "higher", 	emit: ["cancelTutorial", "lexPlayScene:Home", "defaultAnim"], 		icon: "AddisonHome" },
		//Home: { 	text: "Home", setLexConfig: "home",      type: "higher", 	sEmit: "post_to_lex:home", 		icon: "AddisonHome" },
		//lowCancel	      			: {text: "Cancel", type:"sidebarCancel", sEmit:"post_to_lex:cancel", icon:"Done"},
		//tutorialButton      		: {text: "Tutorial", type:"sidebarButton4", sEmit:"post_to_lex:tutorial", sEmit:"lexPlayScene:PowerOn", icon:"Tutorial"},
		//emergencyButton      		: {text: "Emergency", type:"sidebarButton1Red", sEmit:"post_to_lex:emergency", icon:"Emergency"},
// 		MoreFeaturesButton      	: {text: "Motion", 				type:"sidebarButton3", 	sEmit:"post_to_lex:gaitAnalysis", id:"btn3", icon:"Motion", delay:"200"}, //More Features select
// 		VitalsButton	      		: {text: "Vitals", type:"sidebarButton2", sEmit:ctx.vitalsEmit, id:"btn2", icon:"Vitals", delay:"100"}, //Vitals select



	//*********************************************************** MEDICATIONS SCENE *******************************************************************************

		TakeMedsButton: {		text: "Take Medications", 		to:"medicationCompliance",		type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:current meds", 		 		icon:"Medication"},		id:"btn1", //Take Medication select
		PreviousMedsButton: {	text: "Previous Medications",	to: "medicationCompliance/previous-medications",	type:"sidebarButton2", 	emit:"defaultAnim", 	sEmit:"post_to_lex:previous medications", 		 		icon:"Medication"}, 	id:"btn2", //Take Medication select
		UpcomingMedsButton: {	text: "Upcoming Medications",	to: "medicationCompliance/upcoming-medications",	type:"sidebarButton3", 	emit:"defaultAnim", 	sEmit:"post_to_lex:upcoming", 		 		icon:"Medication"}, 	id:"btn3", //Take Medication select

		//ManageMedsButton: {		text: "Review Medications", 									type:"sidebarButton2", 	emit:"defaultAnim", 	sEmit:"post_to_lex:reviewMedications", 	 		icon:"Medication"}, 	id:"btn2", //Manage Medications select

		AlarmOffButton: {		text: 'Alarm Off',	to: 'medicationCompliance/alarm-disabled', type: 'sidebarUnderTable2', emit: "defaultAnim", sEmit:"post_to_lex:alarm off",					icon: 'Yes',	},

		MedTakeYes: { 			text: "Yes", 	to: "medicationCompliance/take-medications", 	type: "sidebarButton1", emit: "defaultAnim", 	sEmit: "post_to_lex:yes" },
		MedTakeNo: { 			text: "No", 	to: "medicationCompliance/alarm-disabled", 	type: "sidebarButton2", emit: "defaultAnim", 	sEmit: "post_to_lex:no" },
		MedTakeNotYet: { 			text: "Not Yet", 	to: "medicationCompliance/alarm-snoozed", 	type: "sidebarButton3", emit: "defaultAnim", 	sEmit: "post_to_lex:not yet" },

		MedTakeReady: { text: "Ready", type: "sidebarButton1", to: "medicationCompliance/take-medications/ready", sEmit:"post_to_lex:ready", emit: "defaultAnim" },
		MedTakeDone: { text: "Done", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:done", emit: "defaultAnim" },
		MedPreviousDone: { text: "Done", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:done", emit: "defaultAnim" },
		MedUpcomingDone: { text: "Done", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:done", emit: "defaultAnim" },
		


		//*********************************************************** First Addison Experience Scene********************************************************************
		TutorialReadyButton: {	
			text: "Ready", 													
			type:"sidebarButton1", 	
			emit:"defaultAnim", 	
			sEmit:"post_to_lex:ready", 		 		
			icon:"ThumbsUp"}, 		
			id:"btn1", 
			//Start FAE Ready

		NotificationBarFAE: {	 																type:"notifications", 		 																				}, 			id:"btnNotification",
		AddisonButton: {	 text: "Addison", 													type:"addison", 		emit:"defaultAnim", 													icon:"Addison"}, 		id:"btnAddison",


		FavoriteColorButton: {	text: "Favorite Color", 										type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:favorite color", 		 	icon:"Power"}, 			id:"btn1", //Favorite Color Button
		
		RedButton: {			text: "Red", 													type:"sidebarButton7", 	emit:"defaultAnim", 	sEmit:"post_to_lex:red", 		 				 			},			id:"btn1", //Color Choice Button
		OrangeButton: {			text: "Orange", 												type:"sidebarButton8", 	emit:"defaultAnim", 	sEmit:"post_to_lex:orange", 		 				 		},			id:"btn2", //Color Choice Button
		GreenButton: {			text: "Green", 													type:"sidebarButton9", 	emit:"defaultAnim", 	sEmit:"post_to_lex:green", 		 				 			},			id:"btn3", //Color Choice Button
		BlueButton: {			text: "Blue", 													type:"sidebarButton10", emit:"defaultAnim", 	sEmit:"post_to_lex:blue", 		 				 			},			id:"btn4", //Color Choice Button
		PurpleButton: {			text: "Purple", 												type:"sidebarButton11", emit:"defaultAnim", 	sEmit:"post_to_lex:purple", 		 				 		},			id:"btn5", //Color Choice Button
		PinkButton: {			text: "Pink", 													type:"sidebarButton12", emit:"defaultAnim", 	sEmit:"post_to_lex:pink", 		 				 			},			id:"btn6", //Color Choice Button

		TutorialPrivacyButtonOn: { text: "Privacy On", type: "listenFAE", emit: "defaultAnim", sEmit: "post_to_lex:privacy mode", }, id:"btn1",
		TutorialPrivacyButtonOff: { text: "Privacy Off", type: "stopListenFAE", emit: "defaultAnim", sEmit: "post_to_lex:privacy mode", }, id:"btn2",

		YesButton: {			text: "Yes", 													type:"sidebarYes", 			emit:"defaultAnim", 	sEmit:"post_to_lex:yes", 		 				icon:"Yes"}, 			id:"btn1", //Yes Button
		NoButton: {				text: "No", 													type:"sidebarNo", 			emit:"defaultAnim", 	sEmit:"post_to_lex:no", 		 				icon:"No"}, 			id:"btn2", //No Button

		listen: { text: "", type: "listen", emit: "listen" },

//DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-

	/**************************************************************************************
	 *																					  *
	 * 								    German Elements	- DE								  *
	 * 																					  *
	 *************************************************************************************/

	//************** GERMAN HOME SCENE/universal? *******************

		MedicationsButtonDE: {	text: "Medikamente", 		setLexConfig: 'medications', 		type: "sidebarButton1", 	emit: "defaultAnim", 	 sEmit:"post_to_lex:Medikamente", 			icon:"Medication"}, 	id:"Sbtn1", //Medications select
		VitalsButtonDE: {		text: "Vitale", 			setLexConfig: 'vitals', 			type: "sidebarButton2", 	emit: "defaultAnim", 	 sEmit:"post_to_lex:Vitale", 			icon:"Vitals"}, 		id:"btn2",  //Vitals select
		DoneButtonDE: {			text: "Erledigt", 												type: "sidebarDone", 		emit: "defaultAnim",	 sEmit:"post_to_lex:Erledigt", 				icon:"Done"}, 			id:"sDone", //Manage Medications select
		BackButtonDE: {			text: "Zurück",													type: "sidebarDone", 		emit: "defaultAnim",	 sEmit:"post_to_lex:Zurück", 				icon:"Left"}, // Back button

		allBackDE: {			text: "Zurück",				to: "/",							type: "sidebarDone",								 sEmit: "post_to_lex:Zurück",				icon: "Left",		},


		tutorialButtonDE: { 	text: "Anleitung", 												type: "sidebarButton3", 	emit: "defaultAnim", 	 sEmit: "post_to_lex:Anleitung", 				icon: "Tutorial" },
		TutorialButtonDE: { 	text: "Anleitung", 												type: "sidebarButton3", 	emit: "defaultAnim", 	 sEmit: "post_to_lex:Anleitung", 				icon: "Tutorial" },

		lowCancelDE: { 			text: "Zurück", 												type: "sidebarDone", 		emit: "defaultAnim", 	 sEmit: "post_to_lex:Erledigt", 			icon: "Left" },
		LowCancelDE: { 			text: "Zurück", 												type: "sidebarDone", 		emit: "defaultAnim", 	 sEmit: "post_to_lex:Erledigt", 			icon: "Left" },

		//HomeDE: { 	text: "Startseite", setLexConfig: "home",      type: "higher", 	emit: ["cancelTutorial", "lexPlayScene:Home", "defaultAnim"], 		icon: "AddisonHome" },


	//************** GERMAN MEDICATIONS SCENE *******************

		TakeMedsButtonDE: {		text: "Medikamenteneinnahme", 									type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Medikamenteneinnahme", 		icon:"Medication"},		id:"btn1", //Take Medication select
		PreviousMedsButtonDE: {	text: "Vorherig Medikamente", 									type:"sidebarButton2", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Vorherig Medikamente", 		icon:"Medication"}, 	id:"btn2", //Take Medication select
		UpcomingMedsButtonDE: {	text: "Weiter Medikamente", 									type:"sidebarButton3", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Weiter Medikamente", 		icon:"Medication"}, 	id:"btn3", //Take Medication select



	//************** GERMAN VITALS SCENE *******************

		takeVitalDE: {				text: "Messe Vital",		to: "/sceneVitals/takeVital",		type: "sidebarButton1",								sEmit: "post_to_lex:messe vital",			icon: "Vitals",				id: "btn1",		},
		TakeVitalDE: {				text: "Messe Vital",		to: "/sceneVitals/takeVital",		type: "sidebarButton1",								sEmit: "post_to_lex:messe vital",			icon: "Vitals",				id: "btn1",		},


		takeHistoryDE: {			text: "Verlauf Anzeigen",	to: "/sceneVitals",					type: "sidebarButton2",		sEmit: "post_to_lex:Verlauf anzeigen",										icon: "VitalsHistory",		id: "btn2",		},
		TakeHistoryDE: {			text: "Verlauf Anzeigen",	to: "/sceneVitals",					type: "sidebarButton2",		sEmit: "post_to_lex:Verlauf anzeigen",										icon: "VitalsHistory",		id: "btn2",		},

		backCancelDE: { 			text: "Beenden",												type: "sidebarDone", 		emit: "defaultAnim", 	sEmit: "post_to_lex:beenden",				icon: "No" },
		BackCancelDE: { 			text: "Beenden", 												type: "sidebarDone", 		emit: "defaultAnim", 	sEmit: "post_to_lex:beenden",				icon: "No" },

		allBackDE: {				text: "Zurück",				to: "/",							type: "sidebarDone",								sEmit: "post_to_lex:Erledigt",				icon: "Left",		},

	//************** German FAE Scene ************************

	TutorialReadyButtonDE: {		text: "Bereit", 												type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:bereit", 		 		icon:"ThumbsUp"}, 		id:"btn1", //Start FAE Ready

	NotificationBarFAEDE: {	 																		type:"notifications", 		 																				}, 			id:"btnNotification",
	AddisonButtonDE: {	 			text: "Addison", 												type:"addison", 		emit:"defaultAnim", 													icon:"Addison"}, 		id:"btnAddison",

	FavoriteColorButtonDE: {		text: "Lieblingsfarbe", 										type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:lieblingsfarbe", 		 	icon:"Power"}, 			id:"btn1", //Favorite Color Button
	
	RedButtonDE: {					text: "Rot", 													type:"sidebarButton7", 	emit:"defaultAnim", 	sEmit:"post_to_lex:rot", 		 				 			},			id:"btn1", //Color Choice Button
	OrangeButtonDE: {				text: "Orange", 												type:"sidebarButton8", 	emit:"defaultAnim", 	sEmit:"post_to_lex:orange", 		 				 		},			id:"btn2", //Color Choice Button
	GreenButtonDE: {				text: "Grün", 													type:"sidebarButton9", 	emit:"defaultAnim", 	sEmit:"post_to_lex:grün", 		 				 			},			id:"btn3", //Color Choice Button
	BlueButtonDE: {					text: "Blue", 													type:"sidebarButton10", emit:"defaultAnim", 	sEmit:"post_to_lex:blue", 		 				 			},			id:"btn4", //Color Choice Button
	PurpleButtonDE: {				text: "Violett", 												type:"sidebarButton11", emit:"defaultAnim", 	sEmit:"post_to_lex:violett", 		 				 		},			id:"btn5", //Color Choice Button
	PinkButtonDE: {					text: "Pink", 													type:"sidebarButton12", emit:"defaultAnim", 	sEmit:"post_to_lex:pink", 		 				 			},			id:"btn6", //Color Choice Button

	TutorialPrivacyButtonOnDE: { text: "Privater Modus", type: "listenFAEDE", emit: "defaultAnim", sEmit: "post_to_lex:privater modus", }, id:"btn1",
	TutorialPrivacyButtonOffDE: { text: "Privater Modus", type: "stopListenFAEDE", emit: "defaultAnim", sEmit: "post_to_lex:privater modus", }, id:"btn2",

	YesButtonDE: {			text: "Ja", 													type:"sidebarYes", 		emit:"defaultAnim", 	sEmit:"post_to_lex:ja", 		 				icon:"Yes"}, 			id:"btn1", //Yes Button
	NoButtonDE: {			text: "Nein", 													type:"sidebarNo", 		emit:"defaultAnim", 	sEmit:"post_to_lex:nein", 		 				icon:"No"}, 			id:"btn2", //No Button

	listenDE: { text: "Privater Modus", type: "listenDE", emit: "listen" },



		  bloodPressure: {			text: "Blood Pressure",			to: "/sceneVitals/takeVital/bloodPressure",			type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:bloodpressure",		icon: "BloodPressure",		  },
		  pulseOximeter: {			text: "Pulse Oximeter",			to: "/sceneVitals/takeVital/bloodOxygen",			type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:pulseox",			icon: "PulseOximeter",		  },
		  glucose: {				text: "Glucometer",				to: "/sceneVitals/takeVital/glucose",				type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:glucose",			icon: "Glucometer",		  },
		  temperature: {			text: "Temperature",			to: "/sceneVitals/takeVital/temperature",			type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:thermometer",		icon: "Temperature",		  },
		  weight: {					text: "Weight Scale",			to: "/sceneVitals/takeVital/weight",				type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:weight",			icon: "Weight",		  },		  
		  airflow: {				text: "Spirometer",				to: "/sceneVitals/takeVital/airflow",				type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:airflow",			icon: "Spirometer",		  },
	  
		  //Vitals Navigation
		  showHistory: {			text: "Review History",			to: "/sceneVitals",			type: "sidebarUnderTable1",			sEmit: "post_to_lex:show history",			icon: "VitalsHistory",			id: "btn12",		  },
	  
		  showTutorial: {			text: "Tutorial",			to: "/sceneVitals/showTutorial",			type: "sidebarButton1",			sEmit: "post_to_lex:tutorial",			icon: "Tutorial",			id: "btn1",		  },
		  vitalReady: {			text: "Ready",			to: "/sceneVitals/vitalReady",			type: "sidebarButton2",			sEmit: "post_to_lex:ready",			icon: "ThumbsUp",			id: "btn2",			startBluetooth: true,		  },
		  interpretReady: {			text: "Ready",			to: "/sceneVitals/takeVital/result",			type: "sidebarButton2",			sEmit: "VitalReceived",			icon: "ThumbsUp",			id: "btn2",		  },
		  vitalBack: {			text: "Back",			to: "/sceneVitals/takeVital",			type: "sidebarDone",			sEmit: "post_to_lex:done",			icon: "Left",		  },
		  vitalDone: {			text: "Done",			to: "/sceneVitals/takeVital",			type: "sidebarDone",			sEmit: "post_to_lex:show menu",			icon: "Done",		  },
		  historyDone: {			text: "Done",			to: "/sceneVitals/historyDone",			type: "sidebarDone",			sEmit: "post_to_lex:done",			icon: "Done",		  },
	  
  
		  //tutorialButton: {			text: "Tutorial",			type: "sidebarButton4",			sEmit: "lexPlayScene:PowerOn",			icon: "Tutorial",		  },
		  lowCancel: {			text: "Back",			to: "/sceneVitals",			type: "sidebarDone",			sEmit: "post_to_lex:show menu",			icon: "Left",			emit: "camAddison",		  },
	  
		  menuBack: {			text: "Back",			to: "/sceneVitals",			type: "sidebarDone",			sEmit: "post_to_lex:show vital menu",			icon: "Left",		  },
		  showMenu: {			text: "Done",			to: "/sceneVitals",			type: "sidebarUnderTable2",			sEmit: "post_to_lex:show vital menu",			icon: "Done",		  },
	  
		  allDone: {			text: "Done",			to: "/",			type: "sidebarDone",			sEmit: "post_to_lex:done",			icon: "Done",		  },
	  
		 // yes: {			text: "Yes",			/*to:"/",	*/ type: "sidebarYes",			sEmit: "post_to_lex:yes",			icon: "Yes",		  },
		 // no: {			text: "No",			/*to:"/",   */ type: "sidebarNo",			sEmit: "post_to_lex:no",			icon: "No",		  },
	  
		  yesVitalResult: {			text: "Yes",			to: "/sceneVitals/takeVital/result",			type: "sidebarYes",			sEmit: "post_to_lex:yes",			icon: "Yes",		  },
		  noVitalResult: {			text: "No",			to: "/sceneVitals",			type: "sidebarNo",			sEmit: "post_to_lex:no",			icon: "No",		  },
	  
		//  next: {			text: "Next",			type: "sidebarNext",			sEmit: "post_to_lex:next",			icon: "Right",		  },
		// previous: {			text: "Previous",			type: "sidebarPrevious",			sEmit: "post_to_lex:previous",			icon: "Left",


//DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-




	});

	/**************************************************************************************
	 *																					  *
	 * 								    Element Groups									  *
	 * 																					  *
	 **************************************************************************************

	 Element groups.
	 This allows us to create, say, one group for the top bar, and hide and show that as it's own thing. */

	store.getActions().addMyElementGroups({
		// exampleSidebar      		: {elements : ["sampleButton1", "sampleButton2"]},
		// yesNo						: {elements : ["yes", "no"]},
		// yesNoLow					: {elements : ["yesLow", "noLow"]},
		// nextPrevious				: {elements : ["next", "previous"]},		
		// //Power On Groups
		// tableMedGroup			    : {elements : ["yesLow", "noLow", "next","previous", "medTable"]},
		// physician			    	: {elements : ["yesLow", "noLow", "next","previous", "physTable"]},		

		
		HomeSidebar: { elements: ["MedicationsButton", "VitalsButton", "TutorialButton", "MedicationsButtonDE", "VitalsButtonDE", "TutorialButtonDE"] },
	//	HomeMedSidebar      		: {elements : ["TakeMedsButton", "ManageMedsButton","BackButton", "PreviousMedsButton", "UpcomingMedsButton"]},
		moreFeaturesSidebar: { elements: ["MotionAnalysisButton", "DoneButton"] },
		MedsGroup: { elements: ["TakeMedsButton", "UpcomingMedsButton", "PreviousMedsButton", "Home", "MedTakeDone", "MedTakeReady", "MedPreviousDone", "MedUpcomingDone"]},

		assistGroup: { elements: ["emergencyButton", "pocketMDButton", "supportButton", "tutorialButton", "lowCancel", "alexaButton"] },
		assistPowerOn: { elements: ["emergencyButton", "pocketMDButton", "supportButton", "backCancel"] },

		AssistGroup: { elements: ["EmergencyButton", "PocketMDButton", "SupportButton", "TutorialButton", "LowCancel", "AlexaButton"] },
		AssistPowerOn: { elements: ["EmergencyButton", "PocketMDButton", "SupportButton", "BackCancel"] },

		TutorialGroup: { elements: ["TutorialReadyButton", "TutorialReadyButtonDE"]},
		AddisonGroup:  { elements: ["AddisonButton", "AddisonButtonDE"], stick: true},
		NotificationGroup: { elements: ["NotificationBarFAE", "NotificationBarFAEDE"], stick: true},
		FavoriteColorGroup:  { elements: ["FavoriteColorButton", "FavoriteColorButtonDE"]},
		ColorGroup: { elements: ["RedButton", "OrangeButton", "GreenButton", "BlueButton", "PurpleButton", "PinkButton", "RedButtonDE", "OrangeButtonDE", "GreenButtonDE", "BlueButtonDE", "PurpleButtonDE", "PinkButtonDE"]},
		PrivacyGroup: { elements: ["TutorialPrivacyButtonOn", "TutorialPrivacyButtonOff", "TutorialPrivacyButtonOnDE", "TutorialPrivacyButtonOffDE"], stick: true},
		YesNoTutorialGroup: { elements: ["YesButton", "NoButton", "YesButtonDE", "NoButtonDE"]},
		listenGroup: { elements: ["listen", "listenDE"], stick: true},
		HomeGroup: { elements: ["Home", "HomeDE"]},


		takeHistory: { elements: ["takeVital", "takeHistory", "allBack"] },
		takeHIstoryDE: { elements: ["TakeVitalDE", "takeHistoryDE", "allBackDE"] },
		takeVitalMenu: { elements: ["showTutorial", "vitalReady", "vitalBack","takeVitalDE", "showTutorialDE", "vitalReadyDE", "vitalBackDE"] },
		takeVitalReady: { elements: ["vitalReady", "vitalReadyDE"] },
		interpretVitalReady: { elements: ["interpretReady", "interpretReadyDE"] },
		doneVital: { elements: ["takeVital", "vitalDone", "takeVitalDE", "vitalDoneDE"] },
		doneHistory: { elements: ["showHistory", "showMenu", "showHistoryDE", "showMenuDE"] }, // this is what will show up with the response:
		readyHistory: { elements: ["historyReady", "historyReadyDE"] },

		//medications
		AlarmOffGroup: { elements: ['AlarmOffButton']},
		MedYesNoNotYet: { elements: ['MedTakeYes', 'MedTakeNo', 'MedTakeNotYet']},
		
	});

	//Create the topbar at the beginning of the scene.
	store.getActions().showGroupFunc("topBar");
	store.getActions().hideGroupFunc("addisonHome");

	lexSetup();
}// end setup

function lexSetup() {
	let lexSettings = {
		botAlias: 'dev',
		botName: 'MCP_HomeBot',
		userId: 'username' + Date.now(),
		accept: 'text/plain; charset=utf-8',
		contentType: 'text/plain; charset=utf-8',
		sessionAttributes: { patientGroup: JSON.stringify(window.groupData) }
	}

	logger.debug('setup home scene chatbot');
	Hub.dispatch("setupLex", lexSettings);

	Hub.dispatch("StartSpeechRec");

	//todo: switch to using home event
	//[LOCALIZATION REQUIRED]]
	setTimeout(() => {
		let data = 'home';
		switch(store.getState().primaryLanguage) {
			case 'en-US':
			case 'en-AU':
			case 'en-GB':
				data = 'home';
				break;
			case 'de-AT':
			case 'de-GE':
			case 'de-DE':
				data = 'zu Hause';
				break;
		}

		postToLex(data);
	}, 4000);

}

// Called when play mode stops.
//
function cleanup() {
	store.getActions().hideAllNonStickyGroups(null);
	store.getActions().removeMyElements(store.getState().elements);
	store.getActions().removeMyElementGroups(store.getState().groups);
}

export const HomeSceneElements = {
	setup,
	cleanup,
};