import * as BABYLON from 'babylonjs';
import { Logger } from 'aws-amplify';

/**
 * initMaterial
 * @param {BABYLON.AssetContainer} container AssetContainer for the currently loaded scene
 * @param {object} mat object containing special properties for a single material 
 * @param {BABYLON.HDRCubeTexture} hdrTexture optional texture to apply as the reflectionTexture and refractionTexture
 */
export async function initMaterial(container, mat, scene) {
    const logger = new Logger('initMaterials');
    const {
        name,

        alpha,
        transparencyMode,
        alphaMode,

        albedoTexture,
        metallicTexture,
        reflectionTexture,
        refractionTexture,
        reflectivityTexture,
        microSurfaceTexture,
        bumpTexture,
        emissiveTexture,
        opacityTexture,
        ambientTexture,
        detailTexture,

        albedoColor,
        reflectivityColor,
        microSurface,
        emissiveColor,
        ambientColor,

        metallic,
        roughness,
        indexRefraction,
        f0Factor,
        reflectanceColor,

        clearCoat,
        clearCoatIntensity,
        clearCoatRoughness,
        clearCoatIor,
        clearCoatTexture,
        clearCoatTint,
        clearCoatTintColor,
        clearCoatTintDistance,
        clearCoatTintThickness,
        clearCoatTintTexture,

        anisotropic,
        anisotropicIntensity,
        anisotropicDirection,
        anisotropicTexture,

        sheen,
        sheenLinkToAlbedo,
        sheenIntensity,
        sheenColor,
        sheenTexture,
        sheenUseRoughness,
        sheenRoughness,
        sheenAlbedoScaling,

        thicknessTexture,
        minThickness,
        maxThickness,
        maskFromThickness,
        subsurfaceTintColor,
        subsurfaceScattering,
        subsurfaceRefraction,
        linkRefractionWithTransparency,
        subsurfaceTranslucency,
        translucencyIntensity,
        diffusionDistance,

        environmentIntensity,
        specularIntensity,
        emissiveIntensity,
        directIntensity,
        normalIntensity,
        reflectionStrength,

        enableSpecularAntiAliasing,

        normalInvertX,
        normalInvertY,

        glass

    } = mat;

    // logger.debug(`setting properties on material ${name}, config: `, mat);

    // const material = scene.getMaterialByName(name);
    const material = container.materials.find((value) => value.name === name || value.id === name)

    // logger.debug('container.materials: ', container.materials);

    if (!material) {
        logger.error(`no material with name ${name} exists (yet)!`);
        return;
    }
    // logger.debug('found material: ', material);

    // Transparency
    if (alpha) material.alpha = alpha;
    if (transparencyMode) {
        // let mode;
        switch (transparencyMode) {
            case "PBRMATERIAL_ALPHABLEND":
                material.transparencyMode = BABYLON.PBRMaterial.MATERIAL_ALPHABLEND;
                break;
            case 'PBRMATERIAL_ALPHATEST':
                material.transparencyMode = BABYLON.PBRMaterial.MATERIAL_ALPHATEST;
                material.albedoTexture.hasAlpha = true;
                break;
            default:
            // logger.debug('invalid mode specified');
        }
    }
    if (alphaMode) material.alphaMode = alphaMode;


    // Channels
    if (albedoTexture) {
        const { rootUrl, vScale } = albedoTexture;
        const _albedoTexture = new BABYLON.Texture(rootUrl, scene);

        _albedoTexture.getAlphaFromRGB = true;

        if (vScale && typeof vScale === 'number') _albedoTexture.vScale = vScale;

        material.albedoTexture = _albedoTexture;
    }

    if (metallicTexture) {
        const { rootUrl, vScale } = metallicTexture;
        const _metallicTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _metallicTexture.vScale = vScale;

        material.metallicTexture = _metallicTexture;
    }

    if (reflectionTexture) {
        const { rootUrl, rotationY } = reflectionTexture;
        const _reflectionTexture = new BABYLON.HDRCubeTexture(rootUrl, scene, 512);

        if (rotationY && typeof rotationY === 'number') _reflectionTexture.rotationY = rotationY;

        material.reflectionTexture = _reflectionTexture;
    }

    if (refractionTexture) {
        const { rootUrl, rotationY } = refractionTexture;
        const _refractionTexture = new BABYLON.HDRCubeTexture(rootUrl, scene, 512);

        if (rotationY && typeof rotationY === 'number') _refractionTexture.rotationY = rotationY;


        material.refractionTexture = _refractionTexture;
    }

    if (reflectivityTexture) {
        const { rootUrl, vScale } = reflectivityTexture;
        const _reflectivityTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _reflectivityTexture.vScale = vScale;

        material.reflectivityTexture = _reflectivityTexture;
    }

    if (microSurfaceTexture) {
        const { rootUrl, vScale } = microSurfaceTexture;
        const _microSurfaceTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _microSurfaceTexture.vScale = vScale;

        material.microSurfaceTexture = _microSurfaceTexture;
    }

    if (bumpTexture) {
        const { rootUrl, vScale } = bumpTexture;
        const _bumpTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _bumpTexture.vScale = vScale;

        material.bumpTexture = _bumpTexture;
    }

    if (emissiveTexture) {
        const { rootUrl, vScale } = emissiveTexture;
        const _emissiveTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _emissiveTexture.vScale = vScale;

        material.emissiveTexture = _emissiveTexture;
    }

    if (opacityTexture) {
        // logger.debug('setting opacityTexture: ', opacityTexture);
        const { rootUrl, vScale } = opacityTexture;

        const _opacityTexture = new BABYLON.Texture(rootUrl, scene);

        _opacityTexture.hasAlpha = true;
        _opacityTexture.getAlphaFromRGB = true;


        if (vScale && typeof vScale === 'number') _opacityTexture.vScale = vScale;

        material.opacityTexture = _opacityTexture;
    }

    if (ambientTexture) {
        const { rootUrl, vScale } = ambientTexture;
        const _ambientTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _ambientTexture.vScale = vScale;

        material.ambientTexture = _ambientTexture;
    }

    if (detailTexture) {
        const { rootUrl, vScale } = detailTexture;
        const _detailTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _detailTexture.vScale = vScale;

        material.detailTexture = _detailTexture;
    }


    // Lighting & Colors
    if (albedoColor) {
        const [x, y, z] = albedoColor;
        material.albedoColor = new BABYLON.Color3(x, y, z);
    }

    if (reflectivityColor) {
        const [x, y, z] = reflectivityColor;
        material.reflectivityColor = new BABYLON.Color3(x, y, z);
    }

    if (microSurface) material.microSurface = microSurface;

    if (emissiveColor) {
        const [x, y, z] = emissiveColor;
        material.emissiveColor = new BABYLON.Color3(x, y, z);
    }

    if (ambientColor) {
        const [x, y, z] = ambientColor;
        material.ambientColor = new BABYLON.Color3(x, y, z);
    }


    // Metallic Workflow
    if (metallic) material.metallic = metallic;
    if (roughness) material.roughness = roughness;
    if (indexRefraction) material.indexOfRefraction = indexRefraction;
    if (f0Factor) material.metallicF0Factor = f0Factor;
    if (reflectanceColor) {
        const [x, y, z] = reflectanceColor;
        material.metallicReflectanceColor = new BABYLON.Color3(x, y, z);
    }


    // Clear Coat
    if (clearCoat) material.clearCoat.isEnabled = clearCoat;
    if (clearCoatIntensity) material.clearCoat.intensity = clearCoatIntensity;
    if (clearCoatRoughness) material.clearCoat.roughness = clearCoatRoughness;
    if (clearCoatIor) material.clearCoat.indexOfRefraction = clearCoatIor;
    if (clearCoatTexture) {
        const { rootUrl, vScale } = clearCoatTexture;
        const _clearCoatTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') clearCoatTexture.vScale = vScale;

        material.clearCoatTexture = _clearCoatTexture;
    }
    if (clearCoatTint) material.clearCoat.isTintEnabled = clearCoatTint;
    if (clearCoatTintColor) material.clearCoat.tintColor = clearCoatTintColor;
    if (clearCoatTintDistance) material.clearCoat.tintColorAtDistance = clearCoatTintDistance;
    if (clearCoatTintThickness) material.clearCoat.tintThickness = clearCoatTintThickness;
    if (clearCoatTintTexture) material.clearCoat.tintTexture = clearCoatTintTexture;


    // Anisotropic
    if (anisotropic) material.anisotropy.isEnabled = anisotropic;
    if (anisotropicIntensity) material.anisotropy.intensity = anisotropicIntensity
    if (anisotropicDirection) {
        const [x, y] = anisotropicDirection;
        material.anisotropy.direction = new BABYLON.Vector2(x, y);
    }
    if (anisotropicTexture) {
        const { rootUrl, vScale } = anisotropicTexture;
        const _anisotropicTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _anisotropicTexture.vScale = vScale;

        material.anisotropy.texture = _anisotropicTexture;
    }


    // Sheen
    if (sheen) material.sheen.isEnabled = sheen;
    if (sheenLinkToAlbedo) material.sheen.linkSheenWithAlbedo = sheenLinkToAlbedo;
    if (sheenIntensity) material.sheen.intensity = sheenIntensity;
    if (sheenColor) {
        const [x, y, z] = sheenColor;
        material.sheen.color = new BABYLON.Color3(x, y, z);
    }
    if (sheenTexture) {
        const { rootUrl, vScale } = sheenTexture;
        const _sheenTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _sheenTexture.vScale = vScale;

        material.sheen.texture = _sheenTexture;
    }
    if (sheenUseRoughness) material.sheen.useRoughnessFromMainTexture = sheenUseRoughness;
    if (sheenRoughness) material.sheen.roughness = sheenRoughness;
    if (sheenAlbedoScaling) material.sheen.albedoScaling = sheenAlbedoScaling;


    // Subsurface
    if (thicknessTexture) {
        const { rootUrl, vScale } = thicknessTexture;
        const _thicknessTexture = new BABYLON.Texture(rootUrl, scene);

        if (vScale && typeof vScale === 'number') _thicknessTexture.vScale = vScale;

        material.subSurface.thicknessTexture = _thicknessTexture;
    }
    if (minThickness) material.subSurface.minimumThickness = minThickness;
    if (maxThickness) material.subSurface.maximumThickness = maxThickness;
    if (maskFromThickness) material.subSurface.useMaskFromThicknessTexture = maskFromThickness;
    if (subsurfaceTintColor) {
        const [x, y, z] = subsurfaceTintColor;
        material.subSurface.tintColor = new BABYLON.Color3(x, y, z);
    }
    if (subsurfaceScattering) material.subSurface.isScatteringEnabled = subsurfaceScattering;
    if (subsurfaceRefraction) material.subSurface.isRefractionEnabled = subsurfaceRefraction;
    if (linkRefractionWithTransparency) material.linkRefractionWithTransparency = linkRefractionWithTransparency
    if (subsurfaceTranslucency) material.subSurface.isTranslucencyEnabled = subsurfaceTranslucency;
    if (translucencyIntensity) material.subSurface.translucencyIntensity = translucencyIntensity;
    if (diffusionDistance) {
        const [x, y, z] = diffusionDistance;
        material.subSurface.diffusionDistance = new BABYLON.Color3(x, y, z);
    }


    // Levels
    if (environmentIntensity) material.environmentIntensity = environmentIntensity;
    if (specularIntensity) material.specularIntensity = specularIntensity;
    if (emissiveIntensity) material.emissiveIntensity = emissiveIntensity;
    if (directIntensity) material.directIntensity = directIntensity;
    if (reflectionStrength) material.reflectionTexture.level = reflectionStrength

    // Rendering
    if (enableSpecularAntiAliasing) material.enableSpecularAntiAliasing = enableSpecularAntiAliasing // NOT WORKING


    // Normal Map
    if (normalInvertX) material.invertNormalMapX = normalInvertX;
    if (normalInvertY) material.invertNormalMapY = normalInvertY;


    // Custom
    if (normalIntensity) material.bumpTexture.level = normalIntensity;
    if (glass && glass == true) {
        material.roughness = 0;
        material.microSurface = 1;
        material.metallic = 0;
        material.alpha = 0.45;
        material.directIntensity = 0
        material.enableSpecularAntiAliasing = false;
        material.indexOfRefraction = 1;
        material.transparencyMode = BABYLON.PBRMaterial.MATERIAL_ALPHABLEND;

        // Mirror

        // Reflected Meshes
        // const DecorLarge = scene.getMeshByName("DecorLarge");
        // const DecorSmall = scene.getMeshByName("DecorSmall");
        // const Drywall = scene.getMeshByName("Drywall");
        // const SetPieces = scene.getMeshByName("SetPieces");
        // const WallArt = scene.getMeshByName("WallArt");
        // var sphere1 = BABYLON.Mesh.CreateSphere("Sphere1", 16.0, 10.0, scene);
        // var sphere3 = BABYLON.Mesh.CreateSphere("Sphere3", 16.0, 10.0, scene);

        // material.reflectionTexture = new BABYLON.MirrorTexture("mirrorTexture", 512, scene, true);

        // material.reflectionTexture.mirrorPlane = new BABYLON.Plane(0, -1.0, 0, -10.0);

        // material.reflectionTexture.renderList = [sphere1, sphere3];
        // material.reflectionTexture.renderList = [DecorLarge, DecorSmall, Drywall, SetPieces, WallArt];
        // material.reflectionTexture.level = 0.4;//Select the level (0.0 > 1.0) of the reflection



    }

    logger.debug(`finished setting properties for material ${name}`);


    if (name === 'Glass_MAT') {
        logger.debug('found material: ', material);

        try {
            logger.debug('container.meshes: ', container.meshes);

            // Mirror
            // Reflected Meshes
            const DecorLarge = container.meshes.find((value, idx) => {
                return value.id === 'DecorLarge' || value.name === 'DecorLarge'
            });
            const DecorSmall = container.meshes.find((value, idx) => {
                return value.id === 'DecorSmall' || value.name === 'DecorSmall'
            });
            const Drywall = container.meshes.find((value, idx) => {
                return value.id === 'Drywall' || value.name === 'Drywall'
            });
            const SetPieces = container.meshes.find((value, idx) => {
                return value.id === 'SetPieces' || value.name === 'SetPieces'
            });
            const WallArt = container.meshes.find((value, idx) => {
                return value.id === 'WallArt' || value.name === 'WallArt'
            });

            logger.debug('DecorLarge: ', DecorLarge);

            // const DecorSmall = container.getMeshByName("DecorSmall");
            // const Drywall = container.getMeshByName("Drywall");
            // const SetPieces = container.getMeshByName("SetPieces");
            // const WallArt = container.getMeshByName("WallArt");
            // var sphere1 = BABYLON.Mesh.CreateSphere("Sphere1", 16.0, 10.0, scene);
            // var sphere3 = BABYLON.Mesh.CreateSphere("Sphere3", 16.0, 10.0, scene);
            material.reflectionTexture = new BABYLON.MirrorTexture("mirrorTexture", 512, scene, true);

            material.reflectionTexture.mirrorPlane = new BABYLON.Plane(0, -1.0, 0, -10.0);

            // material.reflectionTexture.renderList = [sphere1, sphere3];
            material.reflectionTexture.renderList = [DecorLarge, DecorSmall, Drywall, SetPieces, WallArt];
            // material.reflectionTexture.level = 0.4;//Select the level (0.0 > 1.0) of the reflection
        } catch (e) {
            logger.debug("error setting glass reflection: ", e);
        }
    }

    // logger.debug(`finished setting properties for material ${name}`);
}