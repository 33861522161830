/*
88888888888 .d88888b.  8888888b.  888888b.         d8888 8888888b.  
    888    d88P" "Y88b 888   Y88b 888  "88b       d88888 888   Y88b 
    888    888     888 888    888 888  .88P      d88P888 888    888 
    888    888     888 888   d88P 8888888K.     d88P 888 888   d88P 
    888    888     888 8888888P"  888  "Y88b   d88P  888 8888888P"  
    888    888     888 888        888    888  d88P   888 888 T88b   
    888    Y88b. .d88P 888        888   d88P d8888888888 888  T88b  
    888     "Y88888P"  888        8888888P" d88P     888 888   T88b 
*/
import React, { useEffect } from 'react';

import { useStoreState, useStoreActions } from 'easy-peasy';
import { Hub } from 'aws-amplify';

import Icon from '../Icon.js';
import NotificationBar from '../../TopLevel/NotificationBar/';

const TopbarElementHandler = (elementAttributes, isHiding, handleClick) => {
  // const eventManager = useStoreState(state => state.eventManager);
  const isRecording = useStoreState(state => state.isRecording);
  const toggleIsRecording = useStoreActions(actions => actions.toggleIsRecording);

  const toggleMic = () => {
    toggleIsRecording();

    Hub.dispatch("elmAction", isRecording ? "privacyOn" : "privacyOff");
    Hub.dispatch(isRecording ? 'StartPrivacyMode' : 'StopPrivacyMode');
    // Hub.remove('VocalPrivacyMode');    
  };

  useEffect(() => {
    const _vocalPrivacyMode = async () => {
      toggleMic();
    }

    Hub.listen('VocalPrivacyMode', _vocalPrivacyMode);

    return () => {
      Hub.remove('VocalPrivacyMode', _vocalPrivacyMode);
    }

  });

  //Icons
  let curIcon = Icon(elementAttributes.icon);
  if (curIcon !== undefined) curIcon = (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto', minWidth: "16%" }}>{curIcon}</div>);
  else curIcon = (<div style={{ margin: '0.0vw' }}></div>);

  //Assign the text
  let elementText = (<div style={{ margin: 'auto' }}>{elementAttributes.text}</div>);

  switch (elementAttributes.type) {
    /**
     * TOP BAR
     * assist button, top bar, left
     */

    /**
     * TOP BAR
     * SWITCHE TO assit button to right of Addison button
     */
    case "assist":
      return (
        <button
          className={
            /* CLASSES */
            // `elmBtnPermanent elmBtnAssist elmAnimSlideDown
            // ${isHiding ? 'elmAnimPopOut' : ''}`
            `elmBtnPermanent elmBtnAssist
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            left: '21vw',
            width: '14vw',
            height: '6vh',
            top: '2vh',



            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );



    /**
     * ADDISON
     * Addison button, top bar, left-mid
     */


    /**
     * SWITCHED TO ADDISON to LEFT
     */
    case "addison":
      return (
        <button
          className={
            /* CLASSES */
            // `elmBtnPermanent elmBtnBlue elmAnimSlideDown
            // ${isHiding ? 'elmAnimPopOut' : ''}`
            `elmBtnPermanent elmBtnBlue
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            left: '2vw',
            width: '17vw',
            height: '6vh',
            top: '2vh',



            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );



    /**
     * TERTIARY
     * -
     */
    case "tertiary":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnPermanent elmBtnBlue elmAnimSlideDown
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '36vw',
            width: '20vw',
            height: '6vh',
            top: '2vh',




            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

/**
     * Privacy Button for FAE
     * listening, top bar, left-mid
     */
      // case "privacyFAEon":
      // return (
      //   <button
      //     className={
      //       /* CLASSES */
      //       ` 'elmBtnStartListening elmBtnListen elmAnimSlideDown'
      //       ${isHiding ? 'elmAnimPopOut' : ''}`
      //     }
      //     style={{
      //       /* STYLES */
      //       position: 'absolute',
      //       zIndex: '500000',
      //       right: '2vw',
      //       width: '30vw',
      //       height: '6vh',
      //       top: '2vh',

      //       /*  FLEX  */
      //       display: 'flex',
      //       alignItems: 'center'
      //     }}
      //     onClick={() => handleClick(elementAttributes)}
      //   >
      //     {curIcon}{elementText}
      //   </button>
      // );

      // case "privacyFAEoff":
      // return (
      //   <button
      //     className={
      //       /* CLASSES */
      //       `elmBtnStopListening elmBtnListen elmAnimSlideDown'
      //       ${isHiding ? 'elmAnimPopOut' : ''}`
      //     }
      //     style={{
      //       /* STYLES */
      //       position: 'absolute',
      //       zIndex: '500000',
      //       right: '2vw',
      //       width: '30vw',
      //       height: '6vh',
      //       top: '2vh',

      //       /*  FLEX  */
      //       display: 'flex',
      //       alignItems: 'center'
      //     }}
      //     onClick={() => handleClick(elementAttributes)}
      //   >
      //     {curIcon}{elementText}
      //   </button>
      // );


    /**
     * LISTEN
     * listening, top bar, left-mid
     */

    case "listenFAE":
      return (
        //<div id="btnmic-root" className={on ? 'elmAnimSlideDown' : 'hidden elmAnimSlideDown'}>
        <div id="btnmic-root">
          <button
            id="btnmic"
            className={
              /* CLASSES */
              !isRecording ? 'elmBtnStartListening elmBtnListen elmAnimSlideDown' : 'elmBtnStopListening elmBtnListen elmAnimSlideDown'  
            }
            //onClick={toggleMic}
            onClick={() => handleClick(elementAttributes)}
            style={{
              /* STYLES */
              position: 'absolute',
              zIndex: '500000',
              right: '2vw',
              width: '30vw',
              height: '6vh',
              top: '2vh',

              /*  FLEX  */
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {isRecording ? (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOff")}</div>)
              : (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOn")}</div>)}

            <div style={{ margin: 'auto' }}>
              {isRecording ? 'Privacy Mode: Off' : 'Privacy Mode: On'}
              </div>
              </button>
        </div>
);

case "stopListenFAE":
  return (
    //<div id="btnmic-root" className={on ? 'elmAnimSlideDown' : 'hidden elmAnimSlideDown'}>
    <div id="btnmic-root">
      <button
        id="btnmic"
        className={
          /* CLASSES */
          !isRecording ? 'elmBtnStopListening elmBtnListen elmAnimSlideDown' : 'elmBtnStartListening elmBtnListen elmAnimSlideDown'
        }
        //onClick={toggleMic}
        onClick={() => handleClick(elementAttributes)}
        style={{
          /* STYLES */
          position: 'absolute',
          zIndex: '500000',
          right: '2vw',
          width: '30vw',
          height: '6vh',
          top: '2vh',

          /*  FLEX  */
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {isRecording ? (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOff")}</div>)
          : (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOn")}</div>)}

        <div style={{ margin: 'auto' }}>
          {isRecording ? 'Privacy Mode: On' : 'Privacy Mode: Off'}
          </div>
          </button>
    </div>
);

case "listenFAEDE":
  return (
    //<div id="btnmic-root" className={on ? 'elmAnimSlideDown' : 'hidden elmAnimSlideDown'}>
    <div id="btnmic-root">
      <button
        id="btnmic"
        className={
          /* CLASSES */
          !isRecording ? 'elmBtnStartListening elmBtnListen elmAnimSlideDown' : 'elmBtnStopListening elmBtnListen elmAnimSlideDown'  
        }
        //onClick={toggleMic}
        onClick={() => handleClick(elementAttributes)}
        style={{
          /* STYLES */
          position: 'absolute',
          zIndex: '500000',
          right: '2vw',
          width: '30vw',
          height: '6vh',
          top: '2vh',

          /*  FLEX  */
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {isRecording ? (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOff")}</div>)
          : (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOn")}</div>)}

        <div style={{ margin: 'auto' }}>
          {isRecording ? 'Privater Moduse: aus' : 'Privater Modus: ein'}
          </div>
          </button>
    </div>
);

case "stopListenFAEDE":
return (
//<div id="btnmic-root" className={on ? 'elmAnimSlideDown' : 'hidden elmAnimSlideDown'}>
<div id="btnmic-root">
  <button
    id="btnmic"
    className={
      /* CLASSES */
      !isRecording ? 'elmBtnStopListening elmBtnListen elmAnimSlideDown' : 'elmBtnStartListening elmBtnListen elmAnimSlideDown'
    }
    //onClick={toggleMic}
    onClick={() => handleClick(elementAttributes)}
    style={{
      /* STYLES */
      position: 'absolute',
      zIndex: '500000',
      right: '2vw',
      width: '30vw',
      height: '6vh',
      top: '2vh',

      /*  FLEX  */
      display: 'flex',
      alignItems: 'center'
    }}
  >
    {isRecording ? (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOff")}</div>)
      : (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOn")}</div>)}

    <div style={{ margin: 'auto' }}>
      {isRecording ? 'Privater Modus: ein' : 'Privater Modus: aus'}
      </div>
      </button>
</div>
);
    case "listen":
      return (
        //<div id="btnmic-root" className={on ? 'elmAnimSlideDown' : 'hidden elmAnimSlideDown'}>
        <div id="btnmic-root">
          <button
            id="btnmic"
            className={
              /* CLASSES */
              !isRecording ? 'elmBtnStopListening elmBtnListen elmAnimSlideDown' : 'elmBtnStartListening elmBtnListen elmAnimSlideDown'
            }
            onClick={toggleMic}
            style={{
              /* STYLES */
              position: 'absolute',
              zIndex: '500000',
              right: '2vw',
              width: '30vw',
              height: '6vh',
              top: '2vh',

              /*  FLEX  */
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {isRecording ? (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOff")}</div>)
              : (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOn")}</div>)}

            <div style={{ margin: 'auto' }}>
              {isRecording ? 'Privacy Mode: Off' : 'Privacy Mode: On'}
            </div>
          </button>
        </div>
      );
      case "listenDE":
        return (
          //<div id="btnmic-root" className={on ? 'elmAnimSlideDown' : 'hidden elmAnimSlideDown'}>
          <div id="btnmic-root">
            <button
              id="btnmic"
              className={
                /* CLASSES */
                !isRecording ? 'elmBtnStopListening elmBtnListen elmAnimSlideDown' : 'elmBtnStartListening elmBtnListen elmAnimSlideDown'
              }
              onClick={toggleMic}
              style={{
                /* STYLES */
                position: 'absolute',
                zIndex: '500000',
                right: '2vw',
                width: '30vw',
                height: '6vh',
                top: '2vh',
  
                /*  FLEX  */
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {isRecording ? (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOff")}</div>)
                : (<div style={{ height: 'auto', width: '3vw', lineHeight: '10%', margin: 'auto' }}>{Icon("PrivacyOn")}</div>)}
  
              <div style={{ margin: 'auto' }}>
                {isRecording ? 'Privater Modus: aus' : 'Privater Modus: ein'}
              </div>
            </button>
          </div>
        );
    case "notifications":
      return <NotificationBar />;


    default:
      return undefined;



  }
}


export default TopbarElementHandler;
