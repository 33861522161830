const toFahrenheit = kelvin => Math.round((kelvin - 273) * (9 / 5) + 32);

export const getWeather = results => results && results.data;
export const getCondition = weatherResults =>
  weatherResults &&
  weatherResults.weather &&
  weatherResults.weather[0] &&
  weatherResults.weather[0].main;
export const getTemperature = weatherResults =>
  weatherResults &&
  weatherResults.main &&
  weatherResults.main.temp &&
  toFahrenheit(weatherResults.main.temp);