import * as BABYLON from 'babylonjs';

/**
 * setup options to enable native Babylon asset caching
 */
export function setupSceneCaching() {
  //Tell Babylon that we want to cache resources in IndexedDB (5MB file limit with current version of Chrome)
  BABYLON.Database.IDBStorageEnabled = true;
  
  //setup token for scene loading
  // BABYLON.WebRequest.CustomRequestHeaders.Authorization =`Bearer ${await (await Auth.currentSession()).getAccessToken().getJwtToken()}`;
  // BABYLON.WebRequest.CustomRequestHeaders.authorization =`Bearer ${await (await Auth.currentSession()).getAccessToken().getJwtToken()}`;
  // BABYLON.WebRequest.CustomRequestHeaders['x-lae-region'] = window.ADDISON_REGION;
  // BABYLON.WebRequest.CustomRequestHeaders['cache-control'] = 'max-age=0';
  // BABYLON.WebRequest.CustomRequestHeaders['accept-encoding'] = 'gzip, deflate, br';
  // BABYLON.WebRequest.CustomRequestHeaders['Accept-Encoding'] = 'gzip, deflate, br';
  // BABYLON.WebRequest.CustomRequestHeaders.Accept = '*/*';
  // BABYLON.WebRequest.UseCustomRequestHeaders = true
}
