import { Hub, Logger } from "aws-amplify";
import store from "../_GlobalStateStore/GlobalStateStore";


function lexSetup() {
  const logger = new Logger('VitalsSceneElementsSetup');

  //wait a second before posting display vitals menu to Lex; test to determine if there's a race condition
  // setTimeout(
  //   () => Hub.dispatch("post_to_lex", { data: "display vitals menu" }),
  //   1000
  // );
}

async function setup() {
  /* 
	Updated and Written by Martin Jencka - 7/12/2019.

	
	!!! This script handles setting up element creation, and simplifies the process greatly. Simply add your elements and elementgroups to the lists !!!

	You can call an element from lex or a speech file by using the following commands: 
	 
	Displaying an Element: 
	<mark name="showElement:elementName"/>
	
	Hiding an Element:
	<mark name="hideElement:elementName"/>
	
	Showing an Element Group:
	<mark name="showGroup:groupName"/>
	
	Hiding an Element Group:
	<mark name="hideGroup:groupName"/>

	Hiding all non stickied groups:
	<mark name="hideGroups"/>

	Elements are UI things that need animation. Outlines, tables, etc, go here.
	This is where we setup shorthand for speech to call, or for another function to call, without us having to put a whole element summon in a speech file.
	Use the default options as examples to expand upon.
	Use sEmit for a sumerian emit, and emit for an amplify emit. You can have both.
	
	
	 * Button Types:
	Here is a list of all button types we can adjust. This should account for any button in the scene
	All buttons should be modular and allow for any value, with the exception of *maybe* start and stop listening. That may change.

	Each of these is structured like this
	 - id : Description. When you're creating a button, the id of the type is the only thing you should worry about.

	 - $ at beginning of emit means it will send to the sumerian system bus instead of the amplify hub.

	= Top Bar Buttons = (These probably won't need to be changed from defaults, but who knows)
	 - assist : Assist
	 - addison : Addison
	 - listen : Stop Listening / Start Listening (Probably not modular, hardcoded)
	 - tertiary : Tertiary / button on top. Used primarily for testing.

	= Three Button Sidebar =
	 - sBtn1 : Sidebar Button 1
	 - sBtn2 : Sidebar Button 2
	 - sBtn3 : Sidebar Button 3

	= Yes / No Sidebar =
	 - sYes : Sidebar Yes
	 - sNo : Sidebar No

	= Sidebar Other =
	 - sDone : Sidebar Done
	 - sReady : Sidebar Ready (The one with the flag?)


	* Element Types
	Elements are functionally the same as buttons, but do not use any onclick events. This may be graphs, tables, graphics, titles, etc.

	= Tables =
	 - mTable : Medical Table. Used for most tables / Lists
	 - scatterChart : Scatter Chart, default addison scatter chart.
	
	 **************************************************************************************
	 *																					  *
	 * 								  	  Elements										  *
	 * 																					  *
	 **************************************************************************************/
  //-DC- New
  store.getActions().addMyElements({
    //Home

    tutorial: {
      text: "Tutorial",
      type: "tutorial",
      sEmit: "post_to_lex:tutorial",
      icon: "Tutorial",
    },
    reload: { text: "Reload", type: "tertiary", emit: "reload", delay: "100" },

    //Vital Type
    //originals -- jkeys 6/4/20
    // 		bloodPressure				: {text:"Blood Pressure", 		to:"/sceneVitals/takeVital/bloodPressure",	type:"sidebarButton1", 		sEmit:"post_to_lex:bloodpressure", 		icon:"BloodPressure"},
    // 		pulseOximeter				: {text:"Pulse Oximeter", 		to:"/sceneVitals/takeVital/bloodOxygen",	type:"sidebarButton2", 		sEmit:"post_to_lex:pulseox", 			icon:"PulseOximeter"},
    // 		glucose						: {text:"Glucometer",			to:"/sceneVitals/takeVital/glucose", 		type:"sidebarButton4", 		sEmit:"post_to_lex:glucose", 			icon:"Glucometer"},
    // 		temperature					: {text:"Temperature",			to:"/sceneVitals/takeVital/temperature", 	type:"sidebarButton3", 		sEmit:"post_to_lex:thermometer", 		icon:"Temperature"},
    // 		weight						: {text:"Weight Scale",			to:"/sceneVitals/takeVital/weight",			type:"sidebarButton5", 		sEmit:"post_to_lex:weight", 			icon:"Weight"},
    // 		airflow						: {text:"Spirometer",			to:"/sceneVitals/takeVital/airflow",		type:"sidebarButton6", 		sEmit:"post_to_lex:airflow", 			icon:"Spirometer"},

    //Vital Type
    bloodPressure: {      text: "Blood Pressure",      to: "/sceneVitals/takeVital/bloodPressure",      type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:bloodpressure",      icon: "BloodPressure",    },
    pulseOximeter: {      text: "Pulse Oximeter",      to: "/sceneVitals/takeVital/bloodOxygen",      type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:pulseox",      icon: "PulseOximeter",    },
    glucose: {      text: "Glucometer",      to: "/sceneVitals/takeVital/glucose",      type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:glucose",      icon: "Glucometer",    },
    temperature: {      text: "Temperature",      to: "/sceneVitals/takeVital/temperature",      type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:thermometer",      icon: "Temperature",    },
    weight: {      text: "Weight Scale",      to: "/sceneVitals/takeVital/weight",      type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:weight",      icon: "Weight",    },
    airflow: {      text: "Spirometer",      to: "/sceneVitals/takeVital/airflow",      type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:airflow",      icon: "Spirometer",    },


//DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE

    //Vital Type
    bloodPressureDE: {      text: "Blutdruck",                to: "/sceneVitals/takeVital/bloodPressure",      type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Blutdruck",                  icon: "BloodPressure",    },
    pulseOximeterDE: {      text: "Sauerstoffsättigung",      to: "/sceneVitals/takeVital/bloodOxygen",        type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Sauerstoffsättigung",        icon: "PulseOximeter",    },
    glucoseDE: {            text: "Blutzuckermessgerät",      to: "/sceneVitals/takeVital/glucose",            type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Blutzuckermessgerät",        icon: "Glucometer",    },
    temperatureDE: {        text: "Körpertemperatur",         to: "/sceneVitals/takeVital/temperature",        type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Körpertemperatur",           icon: "Temperature",    },
    weightDE: {             text: "Körpergewicht",            to: "/sceneVitals/takeVital/weight",             type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Körpergewicht",              icon: "Weight",    },
    airflowDE: {            text: "Luftvolumen",              to: "/sceneVitals/takeVital/airflow",            type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Luftvolumen",                icon: "Spirometer",    },



//DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE


    //Vitals Navigation
    takeVital: {      text: "Take Vital",      to: "/sceneVitals/takeVital",      type: "sidebarButton1",      sEmit: "post_to_lex:take vital",      icon: "Vitals",      id: "btn1",    },
    takeHistory: {      text: "Review History",      to: "/sceneVitals",      type: "sidebarButton2",      sEmit: "post_to_lex:show history",      icon: "VitalsHistory",      id: "btn2",    },
    showHistory: {      text: "Review History",      to: "/sceneVitals",      type: "sidebarUnderTable1",      sEmit: "post_to_lex:show history",      icon: "VitalsHistory",      id: "btn12",    },

    showTutorial: {      text: "Tutorial",      to: "/sceneVitals/showTutorial",      type: "sidebarButton1",      sEmit: "post_to_lex:tutorial",      icon: "Tutorial",      id: "btn1",    },    vitalReady: {
      text: "Ready",      to: "/sceneVitals/vitalReady",      type: "sidebarButton2",      sEmit: "post_to_lex:ready",      icon: "ThumbsUp",      id: "btn2",      startBluetooth: true,    },
    interpretReady: {      text: "Ready",      to: "/sceneVitals/takeVital/result",      type: "sidebarButton2",      sEmit: "VitalReceived",      icon: "ThumbsUp",      id: "btn2",    },
    vitalBack: {      text: "Back",      to: "/sceneVitals/takeVital",      type: "sidebarDone",      sEmit: "post_to_lex:done",      icon: "Left",    },
    vitalDone: {      text: "Done",      to: "/sceneVitals/takeVital",      type: "sidebarDone",      sEmit: "post_to_lex:show menu",      icon: "Done",    },
    historyDone: {      text: "Done",      to: "/sceneVitals/historyDone",      type: "sidebarDone",      sEmit: "post_to_lex:done",      icon: "Done",    },

    MotionAnalysisButton: {      text: "Test Gait",      type: "sidebarButton1",      sEmit: "post_to_lex:motionanalysis",      icon: "GaitAnalysis",      id: "btn1",    },

    emergencyButton: {      text: "Emergency",      type: "sidebarButton1Red",      sEmit: "post_to_lex:emergency",      icon: "Emergency",    },
    tutorialButton: {      text: "Tutorial",      type: "sidebarButton4",      sEmit: "lexPlayScene:PowerOn",      icon: "Tutorial",    },
    pocketMDButton: {      text: "pocketMD",      type: "sidebarButton2",      sEmit: "post_to_lex:pocketMD",      icon: "PocketMD",    },
    supportButton: {      text: "Customer Support",      type: "sidebarButton3",      sEmit: "post_to_lex:support",      icon: "CustomerSupport",    },
    lowCancel: {      text: "Back",      to: "/sceneVitals",      type: "sidebarDone",      sEmit: "post_to_lex:show menu",      icon: "Left",      emit: "camAddison",    },

    menuBack: {      text: "Back",      to: "/sceneVitals",      type: "sidebarDone",      sEmit: "post_to_lex:show vital menu",      icon: "Left",    },
    showMenu: {      text: "Done",      to: "/sceneVitals",      type: "sidebarUnderTable2",      sEmit: "post_to_lex:show vital menu",      icon: "Done",    },

    allDone: {      text: "Done",      to: "/",      type: "sidebarDone",      sEmit: "post_to_lex:done",      icon: "Done",    },
    allBack: {      text: "Back",      to: "/",      type: "sidebarDone",      sEmit: "post_to_lex:done",      icon: "Left",    },

    yes: {      text: "Yes",      /*to:"/",	*/ type: "sidebarYes",      sEmit: "post_to_lex:yes",      icon: "Yes",    },
    no: {      text: "No",      /*to:"/",   */ type: "sidebarNo",      sEmit: "post_to_lex:no",      icon: "No",    },

    yesVitalResult: {      text: "Yes",      to: "/sceneVitals/takeVital/result",      type: "sidebarYes",      sEmit: "post_to_lex:yes",      icon: "Yes",    },
    noVitalResult: {      text: "No",      to: "/sceneVitals",      type: "sidebarNo",      sEmit: "post_to_lex:no",      icon: "No",    },

    next: {      text: "Next",      type: "sidebarNext",      sEmit: "post_to_lex:next",      icon: "Right",    },
    previous: {      text: "Previous",      type: "sidebarPrevious",      sEmit: "post_to_lex:previous",      icon: "Left",    },

  });

  /**************************************************************************************
	 *																					  *
	 * 								    Element Groups									  *
	 * 																					  *
	 **************************************************************************************
	 Element groups.
	 This allows us to create, say, one group for the top bar, and hide and show that as it's own thing. */
  //-DC- New
  store.getActions().addMyElementGroups({
    exampleSidebar: { elements: ["sampleButton1", "sampleButton2"] },
    vitalTypes: {
      elements: [
        "bloodPressure",
        "pulseOximeter",
        "glucose",
        "temperature",
        "weight",
        "airflow",
        "menuBack",
      ],
    },
    takeHistory: { elements: ["takeVital", "takeHistory", "allBack"] },
    takeVitalMenu: { elements: ["showTutorial", "vitalReady", "vitalBack"] },
    takeVitalReady: { elements: ["vitalReady"] },
    interpretVitalReady: { elements: ["interpretReady"] },
    doneVital: { elements: ["takeVital", "vitalDone"] },
    doneHistory: { elements: ["showHistory", "showMenu"] }, // this is what will show up with the response:
    readyHistory: { elements: ["historyReady"] },
    yesNo: { elements: ["yes", "no"] },
    // so we need to add the showMenu element to the groups that already exist
    yesNoVitalResult: { elements: ["yesVitalResult", "noVitalResult"] },
    assistGroup: {
      elements: [
        "emergencyButton",
        "pocketMDButton",
        "supportButton",
        "lowCancel",
        "tutorialButton",
      ],
    },
    nextPrevious: { elements: ["next", "previous"], stick: false },
    next: { elements: ["next"], stick: false },
  });

  store.getActions().showGroupFunc("topBar");
  store.getActions().showGroupFunc("addisonHome");

  lexSetup();
}

// Called when play mode stops.
//
function cleanup() {
  //-DC- New
  store.getActions().hideAllNonStickyGroups(null);
  store.getActions().removeMyElements(store.getState().elements);
  store.getActions().removeMyElementGroups(store.getState().groups);
}

// Defines script parameters.
//
// var parameters = [];

export const VitalsSceneElements = {
  setup,
  cleanup,
};
