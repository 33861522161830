/**
 * initCall.js
 * @author Isaac Davalos
 * ~2019
 * @author Jeremy Keys
 * 8/27/2020 (refactored + cleaned)
 */

import AWS from 'aws-sdk';
import moment from 'moment';
import { Hub, Logger, Auth } from 'aws-amplify';
import { navigate } from '@reach/router';
import "amazon-connect-streams"; //get access to the `connect` object

/*global connect */

const logger = new Logger('ConnectDialer');

//TODO: terminate connection from client-side based on some condition

const positionObject = {};

/**
 * send alarm to Rapid Response to notify them of an emergency call programmatically
 */
async function sendAlarm() {
    const creds = await Auth.currentCredentials();    
    const { accessKeyId, secretAccessKey, sessionToken } = creds;
    const lambda = new AWS.Lambda({ accessKeyId, secretAccessKey, sessionToken, region: window.ADDISON_REGION });
    // varifying that geolocation is available and permisions are accesable;
    navigator.geolocation.getCurrentPosition(function (position) {
        //ctx.worldData.location = position.coords;
        if ('coords' in position) {
            positionObject.coords = {};

            if ('latitude' in position.coords) {
                positionObject.coords.latitude = position.coords.latitude;

            }
            if ('longitude' in position.coords) {
                positionObject.coords.longitude = position.coords.longitude;

            }
        }
        const lat = positionObject.coords.latitude;
        const long = positionObject.coords.longitude;
        logger.debug(positionObject.coords.latitude);

        Hub.dispatch("loadHelpScreen");
        Hub.dispatch("StartPrivacyMode");
        Hub.dispatch('hideGroup', { data: 'topBar' });
        Hub.dispatch('hideAllGroups');

        //call this lambda if addison help emit comes from the top level scene (ONly for RRMS emergency phone calls)
        if (window.phoneContact === "addisonHelp") {
            //setting parameters for Rapid post lambda, only used if phone call is evoked for RRMS
            const alarmParams = {
                "time": "" + moment().format("YYYY-MM-DD HH:mm:ss") + "",
                "gpslat": "" + lat + "",
                "gpslong": "" + long + "",
                "account": window.account,
                "signalcode": "GPSMED",
                "text": "Sumerian Alarm Button",
                "connectPhone": window.connectNumber,
            };


            let MedAlarm = {
                InvocationType: 'RequestResponse',
                FunctionName: 'arn:aws:lambda:us-east-1:479226928101:function:POST_Rapid',
                Payload: JSON.stringify(alarmParams),
            };
            logger.debug(alarmParams);

            lambda.invoke(MedAlarm).promise().then(data => logger.debug(data)).catch(e => logger.warn(e));
        }
    }, function error(msg) {
        alert('Please enable your GPS.');
        logger.debug(msg);
    }, { maximumAge: 0, enableHighAccuracy: true }); //end of get current position
}  //end of send alarm funciton

/**
 * initiates a connect call
 */
export function initCall() {
    connectapi();
    setTimeout(sendAlarm, 1250);

    function connectapi() {
        const containerDiv = document.createElement("div");
        containerDiv.setAttribute('style', "display:none; height:465px;width:600px");
        containerDiv.setAttribute('id', 'containerDiv');
        document.body.appendChild(containerDiv);
        logger.debug("Connecting to AWS Connect");

        logger.debug("connect object: ", connect);
        connect.core.initCCP(containerDiv,
            {
                ccpUrl: "https://addisoncare.awsapps.com/connect/ccp#/",
                loginPopup: false,
                softphone: { allowFramedSoftphone: true }
            });

            window._connect = connect;


        //function connectagent(){
        logger.debug("Connecting to Agent");

        connect.contact(subscribeToContactEvents);
        connect.agent(subscribeToAgentEvents);

        //} //end of connect api function
        function subscribeToContactEvents(contact) {
            logger.debug("Subscribing to events for contact");
            if (contact.getActiveInitialConnection()
                && contact.getActiveInitialConnection().getEndpoint()) {
                logger.debug("New contact is from " + contact.getActiveInitialConnection().getEndpoint().phoneNumber);
            } else {
                logger.debug("This is an existing contact for this agent");
            }
            logger.debug("Contact is from queue " + contact.getQueue().name);
            logger.debug("Contact attributes are " + JSON.stringify(contact.getAttributes()));
            contact.onIncoming(handleContactIncoming);
            contact.onAccepted(handleContactAccepted);
            contact.onConnected(handleContactConnected);
            contact.onEnded(handleContactEnded);
        }

        function handleContactIncoming(contact) {
            if (contact) {
                logger.debug("[contact.onIncoming] Contact is incoming. Contact state is " + contact.getStatus().type);
            } else {
                logger.debug("[contact.onIncoming] Contact is incoming. Null contact passed to event handler");
            }
        }
        function handleContactAccepted(contact) {
            if (contact) {
                logger.debug("[contact.onAccepted] Contact accepted by agent. Contact state is " + contact.getStatus().type);
            } else {
                logger.debug("[contact.onAccepted] Contact accepted by agent. Null contact passed to event handler");
            }
        }

        function handleContactConnected(contact) {
            if (contact) {
                logger.debug("[contact.onConnected] Contact connected to agent. Contact state is " + contact.getStatus().type);

                window._connectContact = contact;

                navigate('/call');

                const conn = contact.getAgentConnection();
                logger.debug('conn: ', conn);
                if (window._extension && typeof window._extension === 'string') {
                    logger.debug('sending extension');
                    setTimeout(() => conn.sendDigits(window._extension), 2000);
                }
                //	hideConnectingScreen();

            } else {
                logger.debug("[contact.onConnected] Contact connected to agent. Null contact passed to event handler");
            }
        }
        function handleContactEnded(contact) {
            navigate('/');
            delete window._connectContact;
            delete window._extension;

            if (contact) {
                logger.debug("[contact.onEnded] Contact has ended. Contact state is " + contact.getStatus().type);
                //when call ends load top level scene
                Hub.dispatch('post_to_lex', { data: 'home'});
                // window.location.reload(true); //TODO: better behavior than reload. 
            } else {
                logger.debug("[contact.onEnded] Contact has ended. Null contact passed to event handler");
                //sumerian.SystemBus.emit("playIntroScene");
            }
        }
        function subscribeToAgentEvents(agent) {
            logger.debug("Subscribing to events for agent " + agent.getName());
            //  logger.debug("Subscribing to events for agent " + agent.getName());		
            logger.debug("Agent is currently in status of " + agent.getStatus().name);
            logger.debug(agent.getStatus().name);


            agent.onRefresh(handleAgentRefresh);

            if (agent.getStatus().name !== 'Available') {
                setAvailable();
            }

            logger.debug("Initiate Call");

            //if phone contact type is not Customer Support then dial Rapid
            if (window.phoneContact === "addisonHelp") {
                window.endpoint = connect.Endpoint.byPhoneNumber('+18333826779');//RAPID NUMBER -- DO NOT CHANGE
            }
            else if (window.phoneContact === "addisonCustomerSupport") {
                window.endpoint = connect.Endpoint.byPhoneNumber('+18333245433');				//jkeys work phone -- +1 (575) 528-8761
                window._extension = "2";
            }
            else if (window.phoneContact === "addisonPocketMD") {
                window.endpoint = connect.Endpoint.byPhoneNumber("+18666819208");//POCKETMD NUMBER -- DO NOT CHANGE
            }
            else if (window.phoneContact === "responsibleParty") {
                logger.debug("jibberish");
                window.endpoint = connect.Endpoint.byPhoneNumber('+1' + window.phoneContactNumber);
            } else {
                logger.debug("No Number Detected, Contacting Customer Support");
                window.endpoint = connect.Endpoint.byPhoneNumber("+18333245433");
                window._extension = "2";
            }


            logger.debug(window.endpoint);

            //connect direct dial using phone number from window.endpoint
            agent.connect(window.endpoint, {
                success: function () {
                    logger.debug("Call Initiated");
                },
                failure: function (e) {
                    logger.debug("Call failed to initiate: ", e);
                    // window.location.reload(true);
                }
            });

            function setAvailable() {

                var routableState = agent.getAgentStates().filter(function (state) {
                    return state.type === connect.AgentStateType.ROUTABLE;
                })[0];
                logger.debug("Attempted to set Agent to available");
                agent.setState(routableState, {
                    success: function () {
                        logger.debug("Successfully set agent status to Available")
                    },
                    failure: function () {
                        logger.debug("Failed to set agent status to Available")
                    }
                });
            }
        }
    }//end of connect api function

}


function handleAgentRefresh(agent) {
    logger.debug("[agent.onRefresh] Agent data refreshed. Agent status is " + agent.getStatus().name);
}