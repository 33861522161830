import React from 'react';
import autobind from 'class-autobind';
import * as BABYLON from 'babylonjs'; //hack for Host system (would prefer ES6)
import HOST from 'amazon-sumerian-hosts/dist/host.babylon.js'; //Sumerian host system
import AWS from 'aws-sdk';
import 'babylonjs-loaders';
import {Auth, Hub, Logger} from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';
import {
  handleWindowResize,
  initDefaultLight,
  initPipeline,
  initCamera,
  initSceneListeners,
  initVideoTextures,
  setupScene,
  setupSceneCaching,
  initParticles,  
  initHdrSwap,
  initClothingSwap,
  getSceneLoaded,
  // setupIdleTween,
} from '../js';
import {SceneHandler, ChatbotObject, RecognitionHandler} from '../components/AssetsScene';
// import autoCreds from '../CredentialsModule';
//import CameraViewer from './Camera/CameraViewer';


/**
 * renders the canvas context, and handles initial setup of scene assets
 */
export default class AppScene extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.canvasRef = React.createRef();
    this.debuggerRef = React.createRef();
    this.logger = new Logger('AppScene');
  } // end constructor

  async componentDidMount() {
    // await autoCreds.deploy('AppScene');
    const [session, creds] = await Promise.all([Auth.currentSession(), Auth.currentCredentials()]);
    RecognitionHandler.setup();
    ChatbotObject.setup();
    setupSceneCaching();

    console.log("creds: ", creds);

    const { accessKeyId, secretAccessKey, sessionToken } = creds;

    // Grab ref to the canvas containing the WebGL context
    const canvas = this.canvasRef.current;
    store.getActions().setCanvas(canvas);

    //create the engine, scene, and camera, and configure
    const engine = new BABYLON.Engine(canvas, true, {audioEngine: true}, true);
    const scene = setupScene(engine);
    const [defaultCamera] = initCamera(scene);

    //instantiate the camera and position it
    defaultCamera.attachControl(this.canvasRef.current, true);

    const polly = new AWS.Polly({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: window.ADDISON_REGION
    });
    const presigner = new AWS.Polly.Presigner({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: window.ADDISON_REGION
    });
    
    // const HOST = await import('amazon-sumerian-hosts/dist/host.babylon.js');
    await HOST.aws.TextToSpeechFeature.initializeService(polly, presigner, window.AWS.VERSION);
    
    initPipeline(scene);
    initDefaultLight(scene);
    initParticles(scene);
    initHdrSwap(scene);
    
    //init polly service and load the scene
    const [host] = await Promise.all([
      SceneHandler.loadSceneByName('Addison'),
      SceneHandler.loadSceneByName('Home'),
      SceneHandler.loadSceneByName('Vitals'),
      SceneHandler.loadSceneByName('Medications'),
      SceneHandler.loadSceneByName('Exterior'),
      SceneHandler.loadSceneByName('ActivityDetection'),
      SceneHandler.loadSceneByName('LearningArea'),
      SceneHandler.loadSceneByName('AustriaKitchen'),
      SceneHandler.loadSceneByName('Tablet'),
    ]);

    // For testing only.  Remove after config update /////////////////////////////////////////////////////////////////
    const hairMat = scene.getMaterialByName("Hair_MAT_v01");
    hairMat.albedoColor = new BABYLON.Color3.FromHexString("#2D231E")
    hairMat.metallicReflectanceColor =  new BABYLON.Color3.FromHexString("#544940")
    hairMat.anisotropy.isEnabled = true;
    hairMat.anisotropy.intensity = 0.6;
    hairMat.environmentIntensity = 0.6;
    
    const clothesMat = scene.getMaterialByName("Clothes_MAT_v01");
    const austriaClothesDiff = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Austria_Diff_v01.jpg");
    const austriaClothesNorm = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Scrubs_Austria_Norm_v01.jpg");
    clothesMat.roughness = 0.8;
    clothesMat.albedoTexture = austriaClothesDiff;
    clothesMat.albedoTexture.vScale = -1;
    clothesMat.bumpTexture = austriaClothesNorm;
    clothesMat.bumpTexture.vScale = -1;

    const skinMat = scene.getMaterialByName("Skin_MAT_v01");
    skinMat.albedoColor = new BABYLON.Color3.FromHexString("#E9DDD6");

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    // window.loadedScenes['Exterior'].containers.forEach(container => container.addAllToScene());
    // window.loadedScenes['Tablet'].containers.forEach(container => container.addAllToScene());
    
    //the current scene is the Home scene; make it so #1
    store.getActions().setCurrentSceneName('Home');
    store.getActions().setCurrentParticleNames(['fireSystem', 'fireSystem2']);
    
    /**** set listeners, the default rendering pipeline (FXAA, sample count, etc), and setup the default scene light and Skybox lighting */
    initSceneListeners(scene, host, engine);
    initVideoTextures(scene);
    initClothingSwap(scene);
    
    handleWindowResize(engine);
    // setupIdleTween(defaultCamera);

    engine.runRenderLoop(scene.render.bind(scene));
    engine.resize();
  }

  render() {
    return (
      <>
        <canvas id="renderCanvas" ref={this.canvasRef} />
        <div id="mydiv" ref={this.debuggerRef} />
      </>
    );
  }
} // end AppScene
