import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import Amplify, { API, Auth } from 'aws-amplify'; // eslint-disable-line no-unused-vars
import * as serviceWorker from './serviceWorker';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-named-as-default
// import autoCreds from './CredentialsModule';
import { amplifyConfig } from './ConfigsModule';
import GlobalStateStore from './_GlobalStateStore/GlobalStateStore';
import App from './App';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'production') {
  window.LOG_LEVEL = 'DEBUG';
}

/*
 * Initiate Auth cred deployment (1 of 2 if not already logged in)
 */

// Auth.signOut();


// eslint-disable-next-line camelcase
const { aws_content_delivery_bucket } = amplifyConfig;

Amplify.configure(amplifyConfig);
Auth.configure(amplifyConfig);
API.configure(amplifyConfig);


if (aws_content_delivery_bucket.includes('-dev') && !aws_content_delivery_bucket.includes('-staging')) {
  window.ADDISON_ENV = 'dev';
  window.ADDISON_REGION = 'us-east-1';
  // window.ADDISON_VOICE = 'Salli';
  // window.ADDISON_VOICE_ENGINE = 'standard';
}
if (aws_content_delivery_bucket.includes('-staging')) {
  window.ADDISON_ENV = 'staging';
  window.ADDISON_REGION = 'us-west-2';
  // window.ADDISON_VOICE = 'Salli';
  // window.ADDISON_VOICE_ENGINE = 'standard';
}
if (aws_content_delivery_bucket.includes('-prod')) {
  window.ADDISON_ENV = 'prod';
  window.ADDISON_REGION = 'us-east-1';
  // window.ADDISON_VOICE = 'Salli';
  // window.ADDISON_VOICE_ENGINE = 'standard';
}
if (aws_content_delivery_bucket.includes('-germandev')) {
  window.ADDISON_ENV = 'german';
  window.ADDISON_REGION = 'eu-west-1';
  // window.ADDISON_VOICE = 'Marlene';
  // window.ADDISON_VOICE_ENGINE = 'standard';
}


// Attempt to deploy credentials before rendering if already logged in.
//
// (optional) If not already logged in, set a flag / property stating such
//
const loadingState = { wasLoggedIn: null }; // (optional)

// autoCreds
//   .deploy('index') // optional arg is just for debugging Promise wait logic
//   .then(conf => {
//     // We were still logged in - creds are up to date - ready to render app
//     loadingState.wasLoggedIn = true; // (optional)
//   })
//   .catch(err => {
//     console.log('error deploying index autoCreds: ', err);

//     // Make sure to re-enable Analytics after initial login
//     // somewhere if disabled above  eg. Analytics.enable();,
//     // then send the previously 'ignored' signin event if desired.
//   })
//   .finally(() => {
//     /*
//      * End Init Auth cred deployment (1 of 2 if not already logged in. see App.js)
//      */
ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension

  <AmplifyAuthenticator>
    <StoreProvider store={GlobalStateStore}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </StoreProvider>
  </AmplifyAuthenticator>,
  document.getElementById('root'),
);
// });
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
