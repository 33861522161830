import * as BABYLON from 'babylonjs';
import {initMaterial} from '../js';

export async function loadCharacter(
  scene,
  characterFile,
  animationPath,
  animationFiles,
  shadowGenerator,
  sceneConfig
) {
  // Load character model
  const {character, bindPoseOffset} = await BABYLON.SceneLoader.LoadAssetContainerAsync(
    characterFile
  ).then(container => {
    const [character] = container.meshes;
    const [bindPoseOffset] = container.animationGroups;
    const {materials} = sceneConfig;

    //special materials
    if (materials) {
      for (const material of materials) {
        const hdr = material.hdrTexture === true ? window._hdrTexture : undefined;
        initMaterial(container, material, hdr);
      }
    }

    //TODO: reference name from config
    character.name = 'AddisonRoot';

    // Make the offset pose additive
    if (bindPoseOffset) {
      BABYLON.AnimationGroup.MakeAnimationAdditive(bindPoseOffset);
    }

    // Add everything to the scene
    container.scene = scene;
    container.addAllToScene();

    // Cast shadows but don't receive
    shadowGenerator.addShadowCaster(character, true);
    for (var index = 0; index < container.meshes.length; index++) {
      container.meshes[index].receiveShadows = false;
    }

    return {character, bindPoseOffset};
  });

  const children = character.getDescendants(false);

  // Load animations
  const clips = await Promise.all(
    animationFiles.map((filename, index) => {
      const filePath = `${animationPath}/${filename}`;

      return BABYLON.SceneLoader.LoadAssetContainerAsync(filePath).then(container => {
        const startingIndex = scene.animatables.length;
        const firstIndex = scene.animationGroups.length;

        // Apply animation to character
        container.mergeAnimationsTo(
          scene,
          scene.animatables.slice(startingIndex),
          target => children.find(c => c.name === target.name) || null
        );

        // Find the new animations and destroy the container
        const animations = scene.animationGroups.slice(firstIndex);
        container.dispose();
        scene.onAnimationFileImportedObservable.notifyObservers(scene);

        return animations;
      });
    })
  );

  return {character, clips, bindPoseOffset};
}
