/* eslint-disable no-useless-escape */

import { Auth, Hub, Logger } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import store from '../_GlobalStateStore/GlobalStateStore';
import { timeout} from '@lunit/timeout';
import { RecognitionHandler } from '../components/AssetsScene';
import { pullUserData, postToLex } from '.';

const logger = new Logger('ChatbotHandler');

// JS_LexHandler in Addison Asset Scene
/** Lex Tooling Modifications (ssml)
 *
 * Calls on modifier.speech and .before functions
 * before and during Pollly events.  For .after
 * functions, see lexToolingSsmlAfter.  For caption
 * events, see JS_Captions.
 *
 * @param {object} modifier
 * @param {string} message
 *
 * @return {string} message after all replace functions
 */

function lexToolingSsml(message) {
  // logger.debug('lexToolingSsml: ', message);
  const modifier = store.getState().lexToolingMod;
  // For each modifier
  Object.keys(modifier).forEach(key => {
    // if a replaceRegexSpeech function was created or manually entered
    //
    if (modifier[key].replaceRegexSpeech) {
      // recreate the message, with tooling replacements made
      //
      const res = modifier[key].replaceRegexSpeech(message);
      // logger.debug('lexTooling (ssml): ', key, modifier[key].match);
      if (modifier[key].match) {
        // set new message
        message = res;

        // run before callback(s)
        if (modifier[key].before) {
          modifier[key].before(store);
        }
      }
    } // if modifier[key].replaceRegexSpeech
  }); // forEach
  return message;
} // lexToolingSsml

/**
 * Logic to handle the response received from lex goes here currently only keeps track of the state
 * of the conversation indicating to the state machine wether it should be active or passive listening
 * @param {object} data the response from the dialogflow lambda proxy 
 */
export async function lexHandler(data) {
  store.getState().hostEntity.TextToSpeechFeature.stop();
  Hub.dispatch('hideAllNonStickyElements');
  Hub.dispatch('DefaultTabletScreen');

  window._engine && window._engine.resize();

  //TODO: localize the default message
  if(!data.message) {
    data.message = 'I lost my connection to our servers, please try again shortly or refresh the page.'; //TODO: make the spoken message reflective of actual error conditions
  }

  // Auto-wrap with speak and prosody tags if no speak tag
  //

  if (!data.message.includes('<speak>')) {
    // logger.debug('no <speak> tag.  Adding one now');
    data.message = `<speak><prosody rate = '+10%'><prosody pitch = '+5%'>${data.message}</prosody></prosody></speak>`;
  }
  // Auto-call 'hideAllGroups' between each prompt
  // (except for PowerOn scene bots, topBar utterances, and when Addison misunderstands)
  //
  const exclude = ['AllScenes_Privacy', 'Fallback_Intent']; // , "MedicationManagement"];
  if (store.getState().lexParams.botName.includes('MCP_PowerOn') || !data.intentName || exclude.includes(data.intentName)) {
  } else {
    // don't autohide for table list prompts in MCP_MedicationManagement
    if (store.getState().lexParams.botName.includes('MCP_Medication') && data.slotToElicit && data.slotToElicit.includes('List')) {
      // do nothing
    } else {
      Hub.dispatch('hideAllGroups');
    }
  }

  store.getActions().setLexSessionAttributes({ ...store.getState().lexParams.sessionAttributes, ...data.sessionAttributes });

  const { message, intentName, slotToElicit, inputTranscript, sessionAttributes } = data;

  logger.debug('chatbot response: ', data);

  if (sessionAttributes && sessionAttributes.hasOwnProperty('navigateTo')) {
    Hub.dispatch('navigateTo', { data: sessionAttributes.navigateTo });
  }

  if(intentName && slotToElicit && inputTranscript) {
    store.getActions().setCurrentLexConfig({
      intentName,
      prompt: message,
      slotToElicit,
      inputTranscript,
    });
  }

  try {
    sendLexInteraction(store.getState().lexParams, intentName);
  } catch(e) { /* suppress */ }

  store.getActions().setLastResponse(data);
  store.getActions().setLexSpeaking(true);
  
  if (intentName && intentName !== 'CheckActivity') {
    Hub.dispatch('clearActivityTimeout');
  }

  const captionFilteredMsg = lexToolingSsml(message);

  /** Set message body for Speech config **/
  //multiple messages
  if (data.messages && Array.isArray(data.messages) && data.messages.length > 1) {
    for (const msg of data.messages) {
      //if any of the messages includes the lexPlayScene or alias loadSceneByName marks, wait for scene transition before speaking
      if (msg.includes('lexPlayScene') || msg.includes('loadSceneByName')) {
        await timeout(4000)
      }
      const _filteredMsg = lexToolingSsml(msg);
      Hub.dispatch('sentenceEvent', { data: _filteredMsg });
      await store.getState().host.TextToSpeechFeature.play(_filteredMsg);
    }
  } else /* single message */ {
    if (message.includes('lexPlayScene') || message.includes('loadSceneByName')) {
      await timeout(4000);
    }

    Hub.dispatch('sentenceEvent', { data: captionFilteredMsg });
    await store.getState().host.TextToSpeechFeature.play(captionFilteredMsg);
  }

  // ctx.entityData.speech.updateConfig({
  //   body: captionFilteredMsg, // 		body: message - 1/5/2020
  // });

  if (intentName === 'PatientSelect' && slotToElicit === 'navigationCommand') {
    store.getActions().setWorldDataCamera(data.sessionAttributes.camName);
  }
  // store.getState().host.TextToSpeechFeature.play(message);
}

// //////////////////////////////////////////////////////////////////////////////////////////////////
//                                          Helpers                                               //
// //////////////////////////////////////////////////////////////////////////////////////////////////


function buildLexInteraction(
  { inputStream, botName, botAlias, slots, requestAttributes, sessionAttributes },
  intentName
) {
  const inputTranscript = inputStream;

  const query = `mutation CreateLexInteraction($input: CreateLexInteractionInput!) {
        createLexInteraction(input: $input) {
        id
        }
    }
`;

  const input = {
    owner: 'addisoncare-admin',
    inputTranscript,
    intentName,
    timestamp: Date.now(),
    botName,
    botAlias,
    slots,
    requestAttributes,
    sessionAttributes,
  };

  return {
    input,
    query,
  };
}

async function sendLexInteraction(data, intentName) {
  const { query, input } = buildLexInteraction(data, intentName);

  Hub.dispatch('CreateLexInteraction', {
    query,
    input,
  });
}

// //////////////////////////////////////////////////////////////////////////////////////////////////
//                                  Sumerian Functions                                            //
// //////////////////////////////////////////////////////////////////////////////////////////////////

const changeLexFunc = function (dat) {
  // logger.debug('changeLexFunc: ', dat);
  const { payload } = dat;
  // ctx.lexParams = dat;
  store.getActions().setLexParams(payload);
  // logger.debug('New lex bot assigned', payload);
};

async function setup() {

  const payload = (await Auth.currentSession()).idToken.payload;
  const username = payload['cognito:username'];
  store.getActions().setUsername(username);

  Hub.listen('setupLex', changeLexFunc, true);
  Hub.listen('post_to_lex', input => {
    // logger.debug('postToLex listener, input: ', input);

    const { data } = input.payload;

    postToLex(data);
  });

  Hub.listen('updateSessionAttributes', capsule => {
    const { payload } = capsule;
    const data = payload.data;
    const props = Object.keys(data);
    if (!store.getState().lexParams.sessionAttributes)
      store.getActions().setLexSessionAttributes({});
    props.forEach(prop => {
      // only update the prop if it is present in the payload
      if (data[prop]) store.getState().lexParams.sessionAttributes[prop] = data[prop];
    });
  });

  Hub.listen('updateRequestAttributes', payload => {
    const props = Object.keys(payload);
    if (!store.getState().lexParams.requestAttributes)
      store.getActions().setLexParamsRequestAttributes({});

    props.forEach(prop => {
      // only update the prop if it is present in the payload
      if (payload[prop]) store.getActions().setLexParamsRequestAttributes(payload[prop]);
    });
  });

  Hub.listen('updateLexAttributes', payload => {
    // logger.debug('updateLexAttributes payload: ', payload);
    if (!payload.type || !payload.data) {
      console.warn('no type and/or data object specified in updateLexAttributes');
      return;
    }

    const props = Object.keys(payload.data);

    props.forEach(prop => {
      if (payload.type === 'requestAttribute') {
        // only update the prop if it is present in the payload
        if (payload.data[prop]) {
          store.getActions().setLexSessionAttributes(payload.data[prop]);
        }
      } else if (payload.type === 'sessionAttributes') {
        // only update the prop if it is present in the payload
        if (payload.data[prop]) {
          store.getActions().setLexSessionAttributes(payload.data[prop]);
        }
      }
    });

  });

  // Run during scene setup

  // Setup all core modifier functions
  //
  Object.keys(store.getState().lexToolingMod).forEach(key => {
    // Calling two main types of tooling shortcodes
    // 'capture' and 'standalone'
    //
    // Gives entries in ctx.worldData.lexToolingMod one or more functions
    // and ties into speech and caption replacement functions
    let modifier = store.getState().lexToolingMod[key];
    if (modifier.token.end) modifier.token.type = 'capture';
    else modifier.token.type = 'standalone';
    switch (modifier.token.type) {
      case 'capture':
        // Create regexReplaceSpeech function for entry
        // assuming it does not exist (could be overwritten)
        //
        modifier.replaceRegexSpeech =
          modifier.replaceRegexSpeech ||
          (arg => {
            // matched flag - so we can avoid invoking begin, end, or caption functions
            // for unmatched modifiers
            //
            modifier.match = false;
            let message = arg.replace(
              // Use begin and end tokens to parse captured text
              //
              new RegExp(
                `(?<=^|[^\w])(${modifier.token.begin.replace(
                  /([()])/g,
                  '\\$1'
                )})(.+?)(${modifier.token.end.replace(/([()])/g, '\\$1')})(?=[^\w]|$)`,
                'g'
              ),
              (match, p1, p2, p3, offset, str) => {
                //// logger.debug("match: ", match, "p1: ", p1, "p2: ", p2);
                modifier.match = {
                  full: match,
                  captured: p2,
                };
                if (modifier.caption) {
                  // If there is a caption function, provide unique ssml markup (which
                  // will not interfere with speech) to serve as tokens for capturing the
                  // group a second time, during the captioning phase
                  //
                  return `<mark name='lT:${key}'/>${modifier.speech(
                    match,
                    p2
                  )}<mark name='lT:${key}'/>`;
                } else {
                  return modifier.speech(match, p2);
                }
              }
            );
            return message;
          });
        if (modifier.caption) {
          // only create replaceRegexCaption if modifier.caption is non-null
          //
          modifier.replaceRegexCaption =
            modifier.replaceRegexCaption ||
            (arg => {
              //// logger.debug("matched: ", modifier.match, key);
              if (!modifier.match) return arg;

              return arg.replace(
                // use unique ssml markup defined above as start and end tokens
                new RegExp(`(<mark name='lT:${key}'/>)(.+?)(<mark name='lT:${key}'/>)`, 'g'),

                (match, p1, p2, p3, offset, str) => {
                  //match, p1, p2, p3, offset, str
                  //// logger.debug("match: ", match, "p1: ", p1, "p2: ", p2);
                  return modifier.caption(match, p2);
                }
              );
            });
        }
        break;
      case 'standalone':
        // allow parenthesis in tokens
        //
        modifier.replaceRegexSpeech =
          modifier.replaceRegexSpeech ||
          (arg => {
            modifier.match = false;
            let message = arg.replace(
              // Use begin token to parse standalone shortcode
              //
              new RegExp(
                `(?<=^|[^\w])(${modifier.token.begin.replace(/([()])/g, '\\$1')})(?=[^\w]|$)`,
                'g'
              ),

              (match, p1, offset, str) => {
                //match, p1, p2, p3, ..., offset, str
                //// logger.debug("match: ", match, "p1: ", p1);
                modifier.match = {
                  full: match,
                  captured: p1,
                };
                if (modifier.caption) {
                  return `<mark name='lT:${key}'/>${modifier.speech(
                    match,
                    p1
                  )}<mark name='lT:${key}'/>`;
                } else {
                  return modifier.speech(p1, p1);
                }
              }
            );
            return message;
          });
        if (modifier.caption) {
          // only create replaceRegexCaption if modifier.caption is non-null
          //
          modifier.replaceRegexCaption =
            modifier.replaceRegexCaption ||
            (arg => {
              //// logger.debug("matched?: ", modifier.match, key);
              if (!modifier.match) return arg;
              return arg.replace(
                new RegExp(`(<mark name='lT:${key}'/>)(.+?)(<mark name='lT:${key}'/>)`, 'g'),

                (match, p1, p2, p3, offset, str) => {
                  //match, p1, p2, p3, offset, str
                  //// logger.debug("match: ", match, "p1: ", p1, "p2: ", p2);
                  return modifier.caption(match, p2);
                }
              );
            });
        } // if modifier.caption (standalone)
        break;
      default:
        break;
    }
  });
}

function cleanup() {
}

export const ChatbotObject = {
  setup,
  cleanup,
  lexToolingSsml,
  postToLex,
  lexHandler,
};
