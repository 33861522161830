/**
 * @fileOverview SpeechRecognition controller keeps record of the state of the STT engine
 *               (running/stopped), and holds the logic for handling speech input such as wake words
 *               or special utterances.
 *
 * @emits activeListening     caught the wake word "Addison"
 *
 * @listens passiveListening  Turns on the STT engine
 * @listens stopRecognition   Stop STT engine
 *
 * @author Maria Rodriguez
 * 02/27/2019
 * @author Jeremy Keys 
 * 08/25/2020
 */

// "use strict";

import { Hub } from "aws-amplify";
import store from '../_GlobalStateStore/GlobalStateStore';
import storeActivity from './storeActivity';
import { timeActivity, getCurrentTime } from './timeConverter';
import Artyom from "artyom.js"
import { initCall, CALL_TYPES, pullUserData, playSpeech } from '.';

const artyom = new Artyom();
window._artyom =  artyom;
// const startTime = '08:00';
// let endTime = '12:00';
// const currentTime = formatTime(new Date());
// const currentMiliTime = toMilitaryTime(currentTime);

console.log(artyom);

var _language;

export async function stopArtyom() {
  artyom.fatality();
}

export async function startContinuousArtyom() {
  // artyom.fatality();// use this to stop any of

  const userData = store.getState().user.userData;

  if(!userData) {
    const res = await pullUserData();
    _language = (res.primaryLanguage && res.primaryLanguage.code) || 'en-US';

    //hack, because Artyom doesn't support 'de-AT', only 'de-DE'
    _language = _language === 'de-AT' ? 'de-DE' : _language;

    console.log("recognition language: ", _language);
  }

  setTimeout(function () {// if you use artyom.fatality , wait 250 ms to initialize again.
    artyom.initialize({
      lang: _language,// A lot of languages are supported. Read the docs !
      continuous: true,// Artyom will listen forever
      listen: true, // Start recognizing
      debug: true, // Show everything in the console
      speed: 1 // talk normally
    }).then(function () {
      console.log("Ready to work !");
    });
  }, 250);

  let currentTime;
  
  function storeMessage () {    
    currentTime = getCurrentTime();
    if (currentTime < timeActivity.endTime && currentTime >= timeActivity.startTime) {
      storeActivity();
      console.log('Addison word detected!');
      timeActivity.endTime = currentTime;
    }
  }

  /** english wakewords */
  artyom.on(['Addison']).then((i) => {
    activeListening();

    artyom.on(['*'], true).then((i, res) => {
      onResultHandler(res);
    });

    //after five seconds (hack -- need to do signal processing to determine actual end of input, or maybe artyom has an event)
    // remove the wildcard command, and change the tablet texture back to default

    setTimeout( () => {
      //if nothing detected/sent within 5 seconds (should be, until user is still talking)
      //then switch to the default tablet texture
      //and ???
      Hub.dispatch('DefaultTabletScreen');
      // artyom.removeCommands('*');
    }, 5000);
    
    // This is for activity detection via user say "Addison"

  });
  artyom.on(['Addison *'], true).then((i, res) => {
    // activeListening();
    onResultHandler(res);
    // This is for activity detection via user say "Addison"
    storeMessage()
  });
  artyom.on(['Edison *'], true).then((i, res) => {
    // activeListening();
    onResultHandler(res);
    // This is for activity detection via user say "Addison"
    storeMessage()
  });
  artyom.on(['Allison *'], true).then((i, res) => {
    // activeListening();
    onResultHandler(res);
    // This is for activity detection via user say "Addison"
    storeMessage()
  });
  artyom.on(['Ellison *'], true).then((i, res) => {
    // activeListening();
    onResultHandler(res);
    // This is for activity detection via user say "Addison"
    storeMessage()
  });
  artyom.on(['Madison *'], true).then((i, res) => {
    // activeListening();
    onResultHandler(res);
    // This is for activity detection via user say "Addison"
    storeMessage()
  });

  /** german listeners */
  artyom.on(['Start *'], true).then((i, res) => {
    onResultHandler(res);
    storeMessage();
  });
  artyom.on(['start *'], true).then((i, res) => {
    onResultHandler(res);
    storeMessage();
  });
}

//  Handles text output from STT engine; Wake words will be captured & handled here.
function onResultHandler(event) {
  if (!window.privacyMode) {
    const transcript = event.toLowerCase();
    console.log("Transcript " + transcript);
    window.phoneContactNumber = null;

    //hide all but the topBar
    // Hub.dispatch('hideAllElements', { data: 'topBar'});
    // Hub.dispatch('hideAllNonStickyElements');

    //DE 'customer support'
    // [[LOCALIZATION REQUIRED]]
    if(transcript.includes('kundendienst')) {
      playSpeech({
        payload: {
          data: `[REPLACE WITH GERMAN] Customer support is not supported for German or Austrian subscribers at this time.`
        }
      });
    }

    //[[LOCALIZATION REQUIRED]]
    if (transcript.includes('privacy mode') || transcript.includes('datenschutz-modus an') || transcript.includes('flugmodus ein')) {
      Hub.dispatch('StartPrivacyMode');
    }

    if (transcript.includes("pause")) {
      Hub.dispatch("pauseAudio");
    }
    if (transcript.includes("resume")) {
      Hub.dispatch("playMusic");
    }
    if (transcript.includes("Alexa stop") || transcript.includes("stop")) {
      Hub.dispatch("stopAlexa");
    }
    if (transcript.includes("Alexa")) {
      Hub.dispatch("wakeAlexa");
      Hub.dispatch("passiveListen");
      Hub.dispatch("pauseAudio");
    }
    // [[LOCALIZATION REQUIRED]]
    if (transcript.includes("help")) {
      if(store.getState().primaryLanguage !== 'en-US') {
        switch(store.getState().primaryLanguage) {
          case 'de-AT':
          case 'de-DE':
            playSpeech({
              payload: {
                data: `[REPLACE WITH GERMAN] Emergency support is not supported for German or Austrian subscribers at this time.`
              }
            });
            break;
        }
      } else {
        store.getActions().setIsExiting(true);
        store.getState().hostEntity.TextToSpeechFeature.stop();
        window.phoneContact = "addisonHelp";
        store.getActions().setCallType(CALL_TYPES.monitoring);
        Hub.dispatch("playHelpScene");
        Hub.dispatch("hideAllElements");
        initCall();
      } 
    } else if (transcript.includes("customer support")) {
      console.log('Heard Addison Customer Support');
      store.getState().hostEntity.TextToSpeechFeature.stop();
      store.getActions().setCallType(CALL_TYPES.support);
      store.getActions().setIsExiting(true);
      window.phoneContact = "addisonCustomerSupport";
      Hub.dispatch("playHelpScene");
      Hub.dispatch("hideAllElements");
      initCall();
    } else if (transcript.includes("pocket md")) {
      console.log('Heard Addison PocketMD');
      store.getActions().setCallType(CALL_TYPES.doctor);
      store.getState().hostEntity.TextToSpeechFeature.stop();
      store.getActions().setIsExiting(true);
      window.phoneContact = "addisonPocketMD";
      initCall();
      Hub.dispatch("playHelpScene");
    } else  {
    // } else if (store.getState().sendToLex) {
      Hub.dispatch("post_to_lex", { data: transcript });
      passiveListening();
    }
  }
} //end function onResultHandle

// prevents STT engine to stop unless otherwise stated through the forceStop flag.
export function onEndHandler() {
  if (!store.getState().forceStop) {
    // if (!store.getState().recognizing) store.getState().recognition.start();
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
//                                         Helpers                                                //
////////////////////////////////////////////////////////////////////////////////////////////////////

// Starts STT engine if it has already been started it won't initiate another one.
export function recognitionOn() {
  if (!store.getState().recognizing && !window.privacyMode) {
    // 	console.log(ctx.recognition);
    try {
      // store.getState().recognition.start();
      store.getActions().setRecognizing(true);
      // 	console.log("Recognition Started");
    } catch (e) {
      // 		console.log("error starting recognition, e:", e);
    }
  } else {
    // 	console.log("Recognition is Already On");
  }
}

//  aborts STT engine last caught values will be discarded without handling.
export function recognitionOff() {
  store.getActions().setForceStop(true);
  // sumerian.recognizing = false;
  store.getActions().setRecognizing(false);
  store.getActions().setSendToLex(true);
  //store.getState().recognition.abort();
}

// Initializes the STT engine.
export function recognitionSetup() {
  try {
    startContinuousArtyom();
  } catch (e) {
    console.error("Speech Recognition could not be setup, e:", e);
  }
}

export function toggleListening() {
  store.getState().hostEntity.TextToSpeechFeature.stop();
  // she is active listening
  if (store.getState().sendToLex) {
    passiveListening();
  } else {
    activeListening();
  }
}

export function passiveListening() {
  if (store.getState().recognizing) {
    store.getActions().setForceStop(false);
    store.getActions().setSendToLex(false);

    if (!window.privacyMode) {
      Hub.dispatch("tabletState", { data: "tLdisable" });
      console.log("Emitting false");
      Hub.dispatch("addisonBtnListening", { data: false });
    }

    //Disable Background Blur
    Hub.dispatch("backFadeOff");

    //Restart AFK Timer
    Hub.dispatch("AFKTimerStart");

    //Fade out outline
    //Hub.dispatch("hideAmplifyElement", {"elements" : [ { "type":"listeningBorder"} ] });
  }
}

export function forcePassiveListening() {
  recognitionOff();

  Hub.dispatch("tabletState", { data: "tLenable" });

  //Disable Background Blur
  Hub.dispatch("backFadeOff");

  //Fade out outline
  //Hub.dispatch("hideAmplifyElement", {"elements" : [ { "type":"listeningBorder"} ] });
}

export function activeListening() {
  if (!window.privacyMode) {
    store.getActions().setForceStop(false);
    //store.getState().recognition.abort();
    store.getActions().setSendToLex(true);
    Hub.dispatch("tabletState", { data: "tLenable" });
    Hub.dispatch("addisonBtnListening", { data: true });

    //Blur Background
    Hub.dispatch("backFadeOn");

    //Exit AFK
    Hub.dispatch("defaultAnim");
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
//                                   Sumerian Functions                                           //
////////////////////////////////////////////////////////////////////////////////////////////////////
const _forcePassiveListening = () => {
  forcePassiveListening();
}

const _stopSpeechRec = () => {
  recognitionOff();
}
const _startSpeechRec = () => {
  recognitionOn();
}

export function setup(args) {
  recognitionSetup();

  window.privacyMode = false;

  // flag to determine wether STT engine should come to a full stop.
  // ctx.forceStop = false;
  store.getActions().setForceStop(false);
  // flag to see if engine is currently running
  store.getActions().setRecognizing(false);


  Hub.listen("forcePassiveListening", _forcePassiveListening);

  Hub.listen("StopSpeechRec", _stopSpeechRec);

  Hub.listen("StartSpeechRec", _startSpeechRec);

  // ctx.startPrivacyMode = function () {
  const startPrivacyMode = function () {
    //recognitionOff(ctx);
    // ctx.forceStop = false;
    store.getActions().setForceStop(false);

    artyom.fatality();

    // //store.getState().recognition.abort();
    store.getActions().setSendToLex(false);
    //
    window.privacyMode = true;

    console.log("setting is recording to false");
    store.getActions().setIsRecording(false);

    // 		console.log("Privacy mode started...");
    Hub.dispatch("tabletState", { data: "tLdisable" });

    Hub.dispatch("tabletState", { data: "t2enable" });

    Hub.dispatch("defaultAnim");

    Hub.dispatch("privacyAnimOn");

    // Added by jalford 2020-01-02
    Hub.dispatch("backFadeOff");
  };

  // ctx.stopPrivacyMode = function () {
  const stopPrivacyMode = function () {
    window.privacyMode = false;

    startContinuousArtyom();

    //recognitionOn(ctx);
    // 		console.log("Privacy mode ended...");
    Hub.dispatch("tabletState", { data: "t2disable" });

    // Exit Privacy anims and restart AFK timer
    Hub.dispatch("defaultAnim");
    Hub.dispatch("AFKTimerStart");
  };

  Hub.listen("StartPrivacyMode", startPrivacyMode);
  Hub.listen("StopPrivacyMode", stopPrivacyMode);

  window.addEventListener('beforeunload', () => {
    Hub.remove('StartPrivacyMode', startPrivacyMode);
    Hub.remove('StopPrivacyMode', stopPrivacyMode);
  });

  Hub.listen("activeListen", () => {
    activeListening();
  });
  Hub.listen("passiveListen", () => {
    passiveListening();
  });
  Hub.listen("toggleListen", () => {
    toggleListening();
  });

  //recognitionOn(ctx);
  Hub.listen("emergencyButton", () => {
    loadHelpScene("addisonHelp");
  });
  Hub.listen("pocketMDButton", () => {
    loadHelpScene("addisonPocketMD");
  });
  Hub.listen("customerSupportButton", () => {
    loadHelpScene("addisonCustomerSupport");
  });
  Hub.listen("responsibleParty", ({ payload }) => {
    loadHelpScene("responsibleParty", payload.data);
  });

  function loadHelpScene(phoneContact, phoneNumber = null) {
    // clearTimeout(store.entityData.SnoozeTime);
    store.getActions().setIsExiting(true);
    window.phoneContact = phoneContact;
    window.phoneContactNumber = phoneNumber;
    Hub.dispatch("playHelpScene");
    Hub.dispatch("hideAllElements");
  };
}

export function cleanup(args) {
  const listeners = [
    "emergencyButton",
    "pocketMDButton",
    "customerSupportButton",
    "responsibleParty",
    "forcePassiveListening",
  ];

  listeners.forEach((name) => Hub.remove(name));

  // cleaning listeners and turning off STT engine
  Hub.remove("StopSpeechRec");
  Hub.remove("StartSpeechRec");
  Hub.remove("activeListen");
  Hub.remove("passiveListen");
  Hub.remove("toggleListen");

  Hub.remove("StartPrivacyMode");
  Hub.remove("StopPrivacyMode");
  recognitionOff();
}

export const RecognitionHandler = {
  setup,
  cleanup,
  toggleListening,
  passiveListening,
  forcePassiveListening,
  activeListening
}