/**
 * Global State Store
 *
 * @author [Michael Lyon]
 * @author [Isaiah Fielder]
 */

import { LexRuntime } from 'aws-sdk';
import { createStore, computed, action, thunk } from 'easy-peasy';
import { Logger, Hub } from 'aws-amplify';
import userModel from './models/user';
import eventManagerModel from './models/eventManager';
import medReminders from './models/medReminders';

const logger = new Logger('GlobalStateStore');

/**
 * Model of the global state that can be accessed and mutated throughout the application
 */
const model = {
  user: userModel,
  eventManager: eventManagerModel,
  currentVitalType: 'temperature',
  deviceButtons: undefined,
  setDeviceButtons: action((state, payload) => { state.deviceButtons = payload; }),
  currentBluetoothCallback: undefined,
  setCurrentBluetoothCallback: action((state, payload) => { state.currentBluetoothCallback = payload; }),
  lastReading: undefined,
  setLastReading: action( (state, payload) => { state.lastReading = payload; }),
  customerVitals: [],
  changeCurrentVitalTypeSuccess: action((state, payload) => {
    state.currentVitalType = payload;
  }),
  medReminders,
  snoozeAttempts: 0,
  setSnoozeAttempts: action((state, payload) => state.snoozeAttempts = payload),
  incrementSnoozeAttempts: action((state) => state.snoozeAttempts++),

  changeCurrentVitalType: thunk(async (actions, payload) => {
    actions.changeCurrentVitalTypeSuccess(payload);
  }),

  scenesToLoad: 0,
  isLoadingScene: false,
  displayLoadingScreen: true,
  texturesLoaded: false,
  setDisplayLoadingScreen: action((state, payload) => {
    state.displayLoadingScreen = payload;
  }),
  setTexturesLoaded: action((state, payload) => {
    state.texturesLoaded = payload;
  }),
  setIsLoadingScene: action((state, payload) => {
    state.isLoadingScene = payload;
  }),
  setScenesToLoad: action((state, payload) => {
    state.scenesToLoad = payload;
  }),
  isRecording: true,
  setIsRecordingAct: action((state, payload) => {
    state.isRecording = payload;
  }),
  toggleIsRecording: thunk((actions, payload, helpers) => {
    console.log("toggle is recording, current state: ", helpers.getState().isRecording);
    actions.setIsRecordingAct(!helpers.getState().isRecording);
  }),
  setIsRecording: thunk((actions, payload, helpers) => {
    console.log("set is recording, current state: ", helpers.getState().isRecording);
    actions.setIsRecordingAct(payload);
  }),
  toggleIsLoadingScene: action(state => {
    state.isLoadingScene = !state.isLoadingScene;
  }),
  logger: computed(() => new Logger('[Window] Sumerian')),

  //  Devonte's code for Sumerian scene conversions

  isExiting: undefined,
  setIsExiting: action((state, payload) => {
    state.isExiting = payload;
  }),

  entity: {},

  setEntity: action((state, payload) => {
    state.entity = payload;
  }),

  behaviorManager: undefined,
  behaviors: [],
  pushBehavior: action((state, payload) => {
    state.behaviors.push(payload);
  }),
  setBehaviorManager: action((state, payload) => {
    state.behaviorManager = payload;
  }),

  currentScene: undefined,
  currentSceneName: 'Home',
  currentParticleSystems: [],
  currentParticleNames: [],
  oldEntities: [],
  entities: [],
  introEntities: [],
  addisonAssets: [],
  sceneConfigs: [],
  shadowGenerators: [],
  canvas: undefined,

  callType: undefined,
  setCallType: action((state, payload) => { state.callType = payload; }),

  setCurrentParticleSystems: action((state, payload) => {
    state.currentParticleSystems = payload;
  }),
  pushCurrentParticleSystems: action((state, payload) => {
    state.currentParticleSystems.push(payload);
  }),
  setCurrentParticleNames: action((state, payload) => {
    state.currentParticleNames = payload;
  }),
  pushCurrentParticleNames: action((state, payload) => {
    state.currentParticleNames.push(payload);
  }),

  setCanvas: action((state, payload) => {
    state.canvas = payload;
  }),

  setShadowGenerators: action((state, payload) => {
    state.shadowGenerators = payload;
  }),
  addShadowGenerator: action((state, payload) => {
    state.shadowGenerators.push(payload);
  }),
  setSceneConfigs: action((state, payload) => {
    state.sceneConfigs = payload;
  }),

  setCurrentScene: action((state, payload) => {
    state.currentScene = payload;
  }),
  setCurrentSceneName: action((state, payload) => {
    state.currentSceneName = payload;
  }),
  setOldEntities: action((state, payload) => {
    state.oldEntities = payload;
  }),
  setEntities: action((state, payload) => {
    state.entities = payload;
  }),
  setAddisonAssets: action((state, payload) => {
    state.addisonAssets = payload;
  }),

  setIntroEntities: action((state, payload) => {
    state.introEntities = payload;
  }),

  sceneRoot: undefined,
  setSceneRoot: action((state, payload) => {
    state.sceneRoot = payload;
  }),

  engine: undefined,
  setEngine: action((state, payload) => {
    state.engine = payload;
  }),

  cameras: [],
  camera: undefined, //todo: deprecate in favor of activeCamera
  activeCamera: undefined,
  setCamera: action((state, payload) => {
    state.camera = payload;
    state.activeCamera = payload;
  }),
  setCameras: action((state, payload) => {
    state.cameras = payload;
  }),
  pushCamera: action((state, payload) => {
    state.cameras.push(payload);
  }),

  setHomeTimeout: action((state, payload) => {
    state.worldData.homeTimeout = payload;
  }),

  currentSceneConfig: undefined,
  setCurrentSceneConfig: action((state, payload) => {
    state.currentSceneConfig = payload;
  }),

  entityData: {
    dialogueComponent: {
      name: [],
    },
  },

  setEntityData: action((state, payload) => {
    state.entityData = payload;
  }),

  gcpConfig: undefined,
  setGcpConfig: action((state, payload) => { state.gcpConfig = payload; }),
  username: undefined,
  setUsername: action((state, payload) => { state.username = payload; }),

  lexParams: {
    botAlias: '',
    botName: '',
    userId: '',
    accept: '',
    contentType: '',
    sessionAttributes: {},
    requestAttributes: {},
    inputStream: '',
  },
  setLexParams: action((state, payload) => {
    state.lexParams = payload;
  }),

  setLexParamsRequestAttributes: action((state, payload) => {
    state.lexParams.requestAttributes = payload;
  }),

  setLexSessionAttributes: action((state, payload) => {
    state.lexParams.sessionAttributes = payload;
  }),

  setLexParamsInputStreams: action((state, payload) => {
    state.lexParams.inputStream = payload;
  }),

  changeLexParams: thunk(async (actions, payload) => {
    actions.setLexParams(payload);
  }),

  currentLexConfig: {},

  setCurrentLexConfig: action((state, payload) => {
    state.currentLexConfig = payload;
  }),

  lexruntime: new LexRuntime({ region: window.ADDISON_REGION }), // TODO: set region
  setLexRuntime: action((state, payload) => {
    state.lexruntime = payload;
  }),

  world: {
    entityManager: {
      _etitiesById: undefined,
    },
  },

  elementManagerEnabled: true,
  setElementManagerEnabled: action((state, payload) => {
    state.elementManagerEnabled = payload;
  }),
  toggleElementManagerEnabled: action((state, payload) => {
    state.elementManagerEnabled = !state.elementManagerEnabled;
  }),
  elements: [],
  groups: [],

  setElements: action((state, payload) => {
    state.elements = payload;
  }),
  setGroups: action((state, payload) => {
    state.groups = payload;
  }),

  worldData: {
    myElements: {},
    myElementGroups: {},
    myCurrentGroups: [],
    homeTimeout: 500,
    captionModifier: {
      warn: {
        token: '_warn_',
        func: arg => `<span style="color:orange"><strong>${arg.split('_warn_')[1]}</strong></span>`,
      },
      green: {
        token: '_g_',
        func: arg => `<span style="color:green">${arg.split('_g_')[1]}</span>`,
      },
      bold: {
        token: '__',
        func: arg => `<span style="color:#22cdf4"><strong>${arg.split('__')[1]}</strong></span>`,
      },
    },
  },

  setMyElements: action((state, payload) => {
    state.worldData.myElements = payload;
  }),

  setMyElementGroups: action((state, payload) => {
    state.worldData.myElementGroups = payload;
  }),

  setMyCurrentGroups: action((state, payload) => {
    state.worldData.myCurrentGroups = payload;
  }),

  pushCurrentGroup: action((state, payload) => {
    state.worldData.myCurrentGroups.push(payload);
  }),

  addMyElements: thunk((actions, elements, helper) => {
    // // logger.debug("state: ", state);
    const keys = Object.keys(elements);
    keys.forEach(elementName => {
      // logger.debug(`adding element: ${elementName}`);

      // if (elements[elementName].text) {// 1-13-2020 add elementPhrases
      // 	ctx.worldData.emphasizePhrases[elementName] = elements[elementName].text;
      // }
      if (helper.getState().worldData.myElements[elementName]) {
        logger.error(`Error! ${elementName} already exists in ctx.worldData.myElements`);
        delete elements[elementName];
      }
    });
    actions.setMyElements({
      ...helper.getState().worldData.myElements,
      ...elements,
    });
    actions.setElements(keys);
    return keys;
  }),

  addMyElementGroups: thunk((actions, groups, helper) => {
    const keys = Object.keys(groups);
    // // logger.debug("state:", state);
    keys.forEach(groupName => {
      // logger.debug(`adding group: ${groupName}`);
      //TODO: is this condition necessary?
      if (helper.getState().worldData.myElementGroups[groupName]) {
        logger.error(`Error! ${groupName} already exists in ctx.worldData.myElementGroups`);
        delete groups[groupName];
      } else {
        // add group
        groups[groupName].elements.forEach(elementName => {
          // set group for each element so that devs can use
          // showElement and hideGroup in conjuction
          if (!helper.getState().worldData.myElements[elementName]) {
            logger.error(`element (${elementName}) found in group (${groupName}) is not defined.`);
          } else {
            helper.getState().worldData.myElements[elementName].group = groupName;
          } // else (element does exist)
        });
      } // else (group does not already exist)
    });
    actions.setMyElementGroups({
      ...helper.getState().worldData.myElementGroups,
      ...groups,
    });
    // assure that all elements belong to a group
    Object.keys(helper.getState().worldData.myElements).forEach(elementName => {
      if (!helper.getState().worldData.myElements[elementName].group) {
        logger.error(`element ${elementName} must be part of a group!`);
      }
    });
    actions.setElements(keys);
    return keys;
  }),

  removeMyElements: action((state, elementNames) => {
    // const doNotDelete = ['addisonHome', 'addison', 'notifications', 'listen', 'assist'];
    // elementNames.forEach(elementName => {
    //   if (!doNotDelete.includes(elementName)) {
    //     delete state.worldData.myElements[elementName];
    //   }
    // });
  }),

  removeMyElementGroups: action((state, groups) => {
    // const doNotDelete = ['topBar'];
    // //         // logger.debug("worldData.removeMyElementGroups called");
    // groups.forEach(groupName => {
    //   if (!doNotDelete.includes(groupName)) {
    //     delete state.worldData.myElementGroups[groupName];
    //   }
    // });
  }),

  currentScreen: undefined,
  setCurrentScreen: action((state, payload) => {
    state.currentScreen = payload;
  }),

  /** ************************************************************************************
   *																					  *
   * 							   Core Setup Functionality								  *
   * 																					  *
   ************************************************************************************* */
  /** ***********************************************************************************
          Show Group Function - Pass a group name and it will show any elements in that group.
    ************************************************************************************* */
  showGroupFunc: thunk((actions, grp, helper) => {
    // Here is where we add custom groupings for vitals.
    if (helper.getState().worldData.myElementGroups[grp] != null) {
      // If the element exists in our element definition.
      // logger.debug(`Showing Group ${grp}`);
      // Iterate through every element in that group.
      for (let i = 0; i < helper.getState().worldData.myElementGroups[grp].elements.length; i++) {
        const elm = helper.getState().worldData.myElementGroups[grp].elements[i];

        if (helper.getState().worldData.myElements[elm] != null) {
          const payload = {};
          payload.elements = [];
          payload.elements.push(helper.getState().worldData.myElements[elm]);
          // logger.debug('dispatching to showAmplifyElement with payload: ', payload);
          Hub.dispatch('showAmplifyElement', { data: payload });
          // logger.debug(`...showing element ${elm}`);
        } else {
          logger.error(`Element '${elm}'listed in group '${grp}' does not exist.`);
        }
      }

      helper.getState().worldData.myCurrentGroups.push(grp);
      actions.setCurrentScreen(grp);
    } // Otherwise, error out. Make sure that the element exists in ctx.elements above!
    else {
      logger.error('Trying to create group not listed in JS_SceneElements');
    }
  }),

  showElementFunc: thunk((actions, elm, helper) => {
    let elementData = null;
    // ///// last edit: Maria R 12/3/19 passing data to medTables
    if (Array.isArray(elm)) {
      elementData = elm.slice(1);
      elm = elm[0];
    }

    if (helper.getState().worldData.myElements[elm] != null) {
      // If the element exists in our element definition.
      if (helper.getState().worldData.myElements[elm].group) {
        // If the element belongs to part of a group (mandatory)
        const payload = {}; // using existing code pattern
        payload.elements = [];
        if (elementData) {
          helper.getState().worldData.myElements[elm].data = elementData;
        } // edit Maria R 12/3/19
        payload.elements.push(helper.getState().worldData.myElements[elm]);
        // Hub.dispatch("showAmplifyElement", payload);
        // logger.debug('dispatching to showAmplifyElement with payload: ', payload);
        Hub.dispatch('showAmplifyElement', { data: payload });
        //                 // logger.debug("...showing amplify element: " + elm);
        if (!helper.getState().worldData.myCurrentGroups.includes(helper.getState().worldData.myElements[elm].group)) {
          helper.getState().worldData.myCurrentGroups.push(helper.getState().worldData.myElements[elm].group);
        }
      } // Otherwise, error out.
      else {
        logger.error(
          `Trying to create element ${elm} not listed as part of a group JS_SceneElements: `,
          elm
        );
      }
    } // Otherwise, error out. Make sure that the element exists in ctx.elements above!
    else {
      // -DC- Let Amplify give error if unable to show element?
      logger.error(`Trying to create element ${elm} not listed in JS_SceneElements: `, elm);
    }
  }),

  hideElementFunc: thunk((actions, elm, helper) => {
    //         // logger.debug("hiding element");
    if (helper.getState().worldData.myElements[elm] != null) {
      // If the element exists in our element definition.
      const payload = {}; // using existing code pattern
      payload.elements = [];
      payload.elements.push(helper.getState().worldData.myElements[elm]);
      // Hub.dispatch("hideAmplifyElement", payload);
      Hub.dispatch('hideAmplifyElement', { data: payload });
    } // Otherwise, error out. Make sure that the element exists in ctx.elements above!
    else {
      logger.error(`Trying to hide element ${elm} not listed in JS_SceneElements: `, elm);
    }
  }),

  reloadElementFunc: action((state, elm) => {
    if (state.worldData.myElements[elm] != null) {
      // If the element exists in our element definition.
      const payload = {}; // using existing code pattern
      payload.elements = [];
      payload.elements.push(state.worldData.myElements[elm]);
      payload.reload = true;
      // Hub.dispatch("showAmplifyElement", payload);
      // logger.debug('dispatching to showAmplifyElement with payload: ', payload);
      Hub.dispatch('showAmplifyElement', { data: payload });
    } // Otherwise, error out. Make sure that the element exists in ctx.elements above!
    else {
      logger.error(`Trying to reload element ${elm} not listed in JS_SceneElements: `, elm);
    }
  }),

  hideGroupFunc: thunk((actions, grp, helper) => {
    // Here is where we add custom groupings for vitals.
    if (helper.getState().worldData.myElementGroups[grp] != null) {
      // If the element exists in our element definition.
      // Iterate through every element in that group.
      for (let i = 0; i < helper.getState().worldData.myElementGroups[grp].elements.length; i++) {
        const elm = helper.getState().worldData.myElementGroups[grp].elements[i];

        if (helper.getState().worldData.myElements[elm] != null) {
          const payload = {};
          payload.elements = [];
          payload.elements.push(helper.getState().worldData.myElements[elm]);
          // Hub.dispatch("hideAmplifyElement", payload);
          Hub.dispatch('hideAmplifyElement', { data: payload });
        } else {
          logger.error(`Removing Element '${elm}'listed in group '${grp}' does not exist.`);
        }
      }

      // Remove the group from currentgroups.
      for (let i = 0; i < helper.getState().worldData.myCurrentGroups.length; i++) {
        if (helper.getState().worldData.myCurrentGroups[i] === grp) {
          helper.getState().worldData.myCurrentGroups.splice(i, 1);
        }
      }
    } // Otherwise, error out. Make sure that the element exists in ctx.elements above!
    else {
      logger.error('Trying to remove group not listed in JS_SceneElements');
    }
  }),

  hideAllNonStickyGroups: thunk((actions, grp, helper) => {
    const toHide = [];
    helper.getState().worldData.myCurrentGroups.forEach(i => {
      const group = helper.getState().worldData.myElementGroups[i];
      if (group && !group.stick) {
        toHide.push(i);
      }
    });
    // fix a bug where not all groups are hidden - list was shortened while inside of loop
    toHide.forEach(e => {
      actions.hideGroupFunc(e);
    }); // -DC
  }),

  setWorldData: action((state, payload) => {
    state.worldData = payload;
  }),

  setWorldDataCamera: action((state, payload) => {
    state.worldData.camera = payload;
  }),

  setWorldDataAccessToken: action((state, payload) => {
    state.worldData.accessToken = payload;
  }),

  setWorldDataAccessTokenPlayload: action((state, payload) => {
    state.worldData.accessTokenPayload = payload;
  }),
  setWorldDataPassiveListening: action((state, payload) => {
    state.worldData.PassiveListening = payload;
  }),
  setWorldDataActiveListening: action((state, payload) => {
    state.worldData.ActiveListening = payload;
  }),

  lastResponse: {},
  setLastResponse: action((state, payload) => {
    state.lastResponse = payload;
  }),

  targetBlend: '',
  setTargetBlend: action((state, payload) => {
    state.targetBlend = payload;
  }),

  emoteBlend: '',
  setEmoteBlend: action((state, payload) => {
    state.emoteBlend = payload;
  }),

  lookBlend: '',
  setLookBlend: action((state, payload) => {
    state.lookBlend = payload;
  }),

  targetLookBlend: '',
  setTargetLookBlend: action((state, payload) => {
    state.lastResponse = payload;
  }),

  recognizing: {},
  setRecognizing: action((state, payload) => {
    state.recognizing = payload;
  }),

  recognition: undefined,
  setRecognition: action((state, payload) => {
    state.recognition = payload;
  }),
  setRecognitionOnResult: action((state, payload) => {
    state.recognition.onresult = payload;
  }),
  setRecognitionOnEnd: action((state, payload) => {
    state.recognition.onend = payload;
  }),

  lexSpeaking: false,
  setLexSpeaking: action((state, payload) => {
    state.lexSpeaking = payload;
  }),

  sentenceEvent: undefined,
  setSentenceEvent: action((state, payload) => {
    state.sentenceEvent = payload;
  }),

  sendToLex: undefined,
  setSendToLex: action((state, payload) => {
    state.sendToLex = payload;
  }),
  forceStop: undefined,
  setForceStop: action((state, payload) => {
    state.sendToLex = payload;
  }),

  hostEntity: undefined,
  host: undefined,
  character: undefined,
  addisonVoice: 'Salli',
  primaryLanguage: 'en-US',
  setPrimaryLanguage: action((state, payload) => { state.primaryLanguage = payload; }),
  setAddisonVoice: action((state, payload) => {state.addisonVoice = payload} ),
  setCharacter: action((state, payload) => {
    state.character = payload;
  }),

  setHost: action((state, payload) => {
    state.hostEntity = payload;
    state.host = payload;
  }),
  amplifyToken: undefined,
  setAmplifyToken: action((state, payload) => {
    state.amplifyToken = payload;
  }),

  setPassiveListening: action((state, payload) => {
    state.passiveListening = payload;
  }),

  lexToolingMod: {
    elementName: {
      token: {
        begin: '__',
        end: '__',
      }, // end required for surround type
      speech: (fullMatch, captured) => {
        let replacement = 'unknown element';
        return `<mark name='showElement:${captured}'/>${
          store.getState().worldData.myElements[captured].text || replacement
          }<break time='110ms'/>`;
      },
      caption: (fullMatch, captured) => {
        // if no end, assumed single-token replacement
        return `<span style="color: #ffe146;padding: 0.085em;font-size: 0.92em;font-weight:bold;border-radius: 1vh;"><strong>${captured}</strong></span>`;
      },
      before: () => {
        // return // logger.debug("in before callback - arg: ", ctx);
      },
      after: () => {
        // return // logger.debug("in after callback -arg: ", ctx);
      },
    },
    waiting: {
      // capture | standalone (no end)
      token: {
        begin: '(:waitRandom)',
      }, // end required for surround type

      before: () => {
        return // logger.debug('in before callback');
      },
      after: ctx => {
        store
          .getState()
          .worldData.listenerSsml(
            'emote:waitingRandom',
            'animate:waitingRandom',
            'cam:idleAnimation'
          );
        return // logger.debug('in after callback -arg: ', ctx);
      },
      speech: (fullMatch, captured) => {
        return ''; // remove token from message in ssml destined for Polly
      },
    },
    goSomewhere: {
      // capture | standalone (no end)
      token: {
        begin: '(:go.+?)',
      }, // end required for surround type

      after: (ctx, fullMatch, captured) => {
        switch (captured) {
          case '(:goHome)':
            store
              .getState()
              .worldData.listenerSsml([
                'navigateTo:/',
                'emit:cancelTutorial',
                'transitionBackground:livingroom',
                'emit:camAddison',
                'lexPlayScene:Home',
              ]);
            break;
          default:
            break;
        }
      },
      speech: (fullMatch, captured) => {
        return ''; // remove token from message in ssml destined for Polly
      },
    },
  },

  /** lex actions */
  setLexToolingMod: action((state, payload) => {
    state.lexToolingMod = payload;
  }),

  /** subscription handlers and state */
  currentMessage: undefined,
  messageSubscription: undefined,
  setCurrentMessage: action((state, payload) => {
    state.currentMessage = payload;
  }),
  setMessageSubscription: action((state, payload) => {
    state.messageSubscription = payload;
  }),

  /** scene handler */
  sceneHandler: undefined,
  setSceneHandler: action((state, payload) => {
    state.sceneHandler = payload;
  }),

  /** animation configs */
  poiConfig: undefined,
  gestureConfig: undefined,
  setPoiConfig: action((state, payload) => { state.poiConfig = payload; }),
  setGestureConfig: action((state, payload) => { state.gestureConfig = payload; }),
};

const preloadedState = {};

/* eslint-disable no-underscore-dangle */
const store = createStore(model, preloadedState, {
  devTools: true,
});
/* eslint-enable */

export default store;

export { model };
