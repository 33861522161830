/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "addisoncarev3-20190906090931-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d15vjcwc3rvhbc.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:84ae5c5e-8c9c-4282-953e-ab78156a0fd2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BiApgaLXJ",
    "aws_user_pools_web_client_id": "3lrikrm8j33itbgfbu99icjnog",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ahligu7qhrggti3ynofjojovee.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
