
export function getVoiceFromLanguageCode(languageCode) {
    let _voice = 'Salli';
  
    // [[LOCALIZATION REQUIRED]]
    switch(languageCode) {
      case 'en-US':
      case 'en-AU':
      case 'en-GB':
      case 'en':
        _voice = 'Salli';
        break;
      case 'de-AT':
      case 'de-GE':
      case 'de':
        _voice = 'Marlene';
        break;
      default:
        _voice = 'Salli';
    }
  
    return _voice;
  }