/**
 * resize the engine when the browser tab/context is resized
 * @param {BABYLON.Engine} engine 
 */
export function handleWindowResize(engine) {    
    window.addEventListener('resize', () => {
      engine.resize();
    });

    window.onfocus = function() {
      engine.resize();
    }

    window.onblur = function() {
      engine.resize();
    }
}