// import HOST from 'amazon-sumerian-hosts/dist/host.babylon.js'; //Sumerian host system
import { Hub, Logger } from 'aws-amplify';
import * as BABYLON from 'babylonjs'; //hack for Host system (would prefer ES6)
import HOST from 'amazon-sumerian-hosts/dist/host.babylon.js';
import { setPath, setPOI, initCall } from '.';
import store from '../_GlobalStateStore/GlobalStateStore';
import { SceneHandler } from '../components/AssetsScene/SceneHandler';
import { setCameraPosition, setCameraRotation, setHostPosition, setHostRotation, setActiveCamera } from './listeners';
import { VitalsSceneElements, HomeSceneElements } from '../elements';
import { getVoiceFromLanguageCode, stopArtyom, startContinuousArtyom } from '.'

const logger = new Logger('SceneListeners');

function _loadSceneByName(event) {
  if(store.getState().currentSceneName === event.payload.data) {
    logger.debug('not reloading current scene');
    return;
  }

  Hub.dispatch('fadeInSplash');
  SceneHandler.loadSceneByName(event.payload.data);
  const lower = event.payload.data.toLowerCase();
  switch (lower) {
    case 'vitals':
      VitalsSceneElements.setup();
      break;
    case 'home':
      HomeSceneElements.setup();
      break;
      default:
        logger.warn('invalid scene passed to lexPlayScene or loadSceneByName Hub emits');
  }

}

/**
 * hookup scene-related events (e.g. setCameraPosition, toggleNodeEnabled)
 * @param {BABYLON.Scene} scene scene object
 * @param {Host} host host object
 */
export function initSceneListeners(scene, host, engine) {
  window._scene = window._scene || scene;
  window._host = window._host || host;
  window._engine = window._engine || engine;
  /**
   * scene loading stuff
   */
  Hub.listen('loadSceneByName', _loadSceneByName);
  Hub.listen('lexPlayScene', _loadSceneByName);

  /**
   * position / rotation
   */
  Hub.listen('setCameraPosition', setCameraPosition);
  Hub.listen('setCameraRotation', setCameraRotation);
  Hub.listen('setHostPosition', setHostPosition);
  Hub.listen('setHostRotation', setHostRotation);
  Hub.listen('setActiveCamera', setActiveCamera);


  Hub.listen('supportPhone', () => {
    logger.debug("Support Phone Type");
    window.phoneContact = "addisonCustomerSupport";
    logger.debug("Setting phone to ECG Customer Support");
    initCall();

  });
  Hub.listen('helpPhone', () => {
    logger.debug("Help Phone Type");
    window.phoneContact = "addisonHelp";
    initCall();

  });
  Hub.listen('pocketmdPhone', () => {
    logger.debug("Pocket MD Phone Type");
    window.phoneContact = "addisonPocketMD";
    initCall();

  });


  Hub.listen('toggleNodeEnabled', capsule => {
    const { payload } = capsule;

    const name = payload.data;

    const node = scene.getNodeByID(name);

    let isEnabled = node.isEnabled();

    node.setEnabled(!isEnabled);
  });

  Hub.listen('toggleEnvironmentEnabled', capsule => {
    const node = scene.getNodeByID('RootGrp');

    let isEnabled = node.isEnabled();

    node.setEnabled(!isEnabled);
  });

  Hub.listen('logCameraPosition', () => {
    const camera = store.getState().camera;
    logger.debug('camera.position: ', camera.position);
    logger.debug('camera.rotation: ', camera.rotation);
  });

  Hub.listen('logPosition', capsule => {
    const { payload } = capsule;
    const nodeName = payload.data;

    const node = scene.getNodeByID(nodeName);

    logger.debug('position: ', node.position);
    logger.debug('rotation: ', node.rotation);
  });

  Hub.listen('logNode', capsule => {
    const { payload } = capsule;
    const nodeName = payload.data;

    const node = scene.getNodeByID(nodeName);

    logger.debug(node);
  });

  Hub.listen('hideNode', capsule => {
    const { payload } = capsule;
    const nodeName = payload.data;

    const node = scene.getNodeByID(nodeName);

    node.setEnabled(false);
  });

  Hub.listen('showNode', capsule => {
    const { payload } = capsule;
    const nodeName = payload.data;

    const node = scene.getNodeByID(nodeName);

    node.setEnabled(true);
  });

  Hub.listen('disposeNode', capsule => {
    const { payload } = capsule;
    const nodeName = payload.data;

    const node = scene.getNodeByID(nodeName);

    node.dispose();
  });

  Hub.listen('enableNode', capsule => {
    const { payload } = capsule;
    const nodeName = payload.data;

    const node = scene.getNodeByID(nodeName);

    node.setEnabled(true);
  });

  Hub.listen('disableNode', capsule => {
    const { payload } = capsule;
    const nodeName = payload.data;

    const node = scene.getNodeByID(nodeName);

    node.setEnabled(false);
  });

  Hub.listen('disableTTS', _disableTTS);

  Hub.listen('enableTTS', _enableTTS);

  Hub.listen('disablePOI', () => {
    host.removeFeature('PointOfInterestFeature');
  });

  Hub.listen('enablePOI', async () => {
    const HOST = await import('amazon-sumerian-hosts/dist/host.babylon.js');
    const character = store.getState().character;
    const children = character.getDescendants(false);
    const lookJoint = 'char:def_c_look'; //TODO: reference from scene config
    const lookTracker = children.find(child => child.name === lookJoint);

    try {


      host.addFeature(
        HOST.PointOfInterestFeature,
        false,
        {
          target: window._defaultCamera,
          lookTracker,
          scene,
        },
        {
          layers: store.getState().poiConfig,
        },
        {
          layers: [{name: 'Blink'}],
        }
      );
    } catch (e) {
      //idempotent baby, silently catch error
    }
  });

  Hub.listen('setPOI', capsule => {
    const {payload} = capsule;
    setPOI(payload.data);
  });

  Hub.listen('gesture', capsule => {
    const { payload } = capsule;
    host.GestureFeature.playGesture('Gesture', payload.data);
  });

  Hub.listen('base', capsule => {
    const { payload } = capsule;
    host.GestureFeature.playGesture('Base', payload.data, { force: true });
  });

  Hub.listen('emote', capsule => {
    const { payload } = capsule;
    host.GestureFeature.playGesture('Emote', payload.data);
  });

  window.addEventListener('gesture', evt => {
    logger.debug('event listener gesture evt: ', evt);
    host.GestureFeature.playGesture('Gesture', evt.detail);
  });

  window.addEventListener('emote', evt => {
    logger.debug('event listener emote evt: ', evt);
    host.GestureFeature.playGesture('Emote', evt.detail);
  });

  let debugLayerToggled = false;
  
  const _enableDebugLayer = () => {
    debugLayerToggled = true;
    Hub.dispatch('hideAllElements');
    scene.debugLayer.show({
      overlay: true,
    });
  }

  const _disableDebugLayer = () => {
    debugLayerToggled = false;
    scene.debugLayer.hide();
  };

  Hub.listen('enableDebugLayer', _enableDebugLayer);
  Hub.listen('edl', _enableDebugLayer);

  Hub.listen('disableDebugLayer', _disableDebugLayer);
  Hub.listen('ddl', _disableDebugLayer);

  Hub.listen('toggleDebugLayer', () => {
    const scene = store.getState().scene;

    if (debugLayerToggled) {
      scene.debugLayer.hide();
    } else {
      scene.debugLayer.show({
        overlay: true,
        globalRoot: store.getState().canvas,
      });
    }

    debugLayerToggled = !debugLayerToggled;
  });

  logger.debug('setting navigateTo listener in App');

  const _navigateTo = capsule => {
    const {payload} = capsule;
    const path = payload.data;
    // TODO: regex v alidate the path to verify we're going to a valid path at the very least
    logger.debug('navigateTo: ', {data: path});
    setPath(path);
  }

  Hub.listen('to', _navigateTo);
  Hub.listen('navigateTo', _navigateTo);

  Hub.listen('getUserId', () => {
    Hub.dispatch('userID', {data: store.getState().user.userData.id});
  });

  Hub.listen('clearMedReminder', () => {
    logger.debug('medreminder to turn off >>>>>>>', '18:00:00');
    //TODO?: refactor this to use the actual reminder time, OR remove
    store.getActions().medReminders.deactivateReminder('18:00:00');
  });

  Hub.listen('showEnvironment', (capsule) => {
    const name = capsule.payload.data;
    //don't transition if this is the current scene
    if(store.getState().currentSceneName === name) {
      logger.debug('scene already loaded, not transitioning');
      return;
    }
    store.getActions().setCurrentSceneName(name);
    
    SceneHandler.showEnvironment(name);
  })

  Hub.listen('HideSplashScreen', () => {
    store.getActions().setDisplayLoadingScreen(false);
  });


  Hub.listen('AneltoReceived', () => {
    let sessionAttributes = store.getState().lexParams.sessionAttributes;
    let lastWeekData = JSON.parse(sessionAttributes.lastWeekData);

    logger.debug('lastWeekData: ', lastWeekData);

    Hub.dispatch('vitalsDataReceived', { data: lastWeekData });
  });

  Hub.listen('StartPrivacyMode', (capsule) => {
    const { payload } = capsule;
   }); 

  //Lets set up addison's speech reciever
  Hub.listen('playSpeech', _playSpeech);
   Hub.listen('loadParticlesByName', _loadParticlesByName);
   Hub.listen('setLanguage', _setLanguage);

   const cleanup = () => {
    Hub.remove('playSpeech', _playSpeech);
     Hub.remove('loadParticlesByName', _loadParticlesByName);
     Hub.remove('setLanguage', _setLanguage);
   };

   return cleanup;
}

const _loadParticlesByName = (capsule) => {
  const name = capsule.payload.data;

  SceneHandler.loadParticlesByName(name);
 };


 /**
  * switch the language on the program; this should switch both the voice and the speech recognition language
  * @param {HubCapsule} capsule 
  */
const _setLanguage = (capsule) => {
  const languageCode = capsule.payload.data;
  store.getActions().setPrimaryLanguage(languageCode);
  const voice = getVoiceFromLanguageCode(languageCode);

  //re-enable with the new voice
  _disableTTS();
  _enableTTS({
    payload: {
      data: voice
    }
  })

  stopArtyom();
  startContinuousArtyom();
};

const _playSpeech = async ({payload}) => {
  console.log('Attempting to play speech: ' + payload.data);

  //Stop any speech that's playing.
  if (store.getState().lexSpeaking) {
    await window._host.TextToSpeechFeature.stop();

    //We'll timeout for a short period of time so that way the last speech can actually stop.
    setTimeout(function () {
      window._host.TextToSpeechFeature.play(payload.data);
    }, 50);
  }
};

const _enableTTS = (capsule) => {
  const { payload } = capsule;
  const voice = payload.data;
  window._host.addFeature(HOST.aws.TextToSpeechFeature, false, {
    scene: window._scene,
    attachTo: window._audioAttach,
    voice,
    engine: window._engine,
  });
}

const _disableTTS = (capsule) => {
  window._host.removeFeature('TextToSpeechFeature');
}

export { _enableTTS as enableTTS};
export { _playSpeech as playSpeech };
export { _loadParticlesByName as loadParticlesByName};
export { _setLanguage as setLanguage };
export { setCameraPosition, setCameraRotation, setHostPosition, setHostRotation, setActiveCamera };